import React from "react";
import { Modal, Button, Typography, Avatar, Box, Divider } from "@mui/material";
import './template.css';

const MessageTemplateModal = ({
  showPopup,
  setShowPopup,
  templateDetails,
  handleConfirmTemplate,
  handleCancelTemplate,
  disabled
}) => {
  return (
    <Modal
      open={showPopup}
      onClose={() => setShowPopup(false)}
      aria-labelledby="template-preview-modal"
    >
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          maxWidth: "400px",
          margin: "auto",
          borderRadius: "12px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", color: "#007AFF", textAlign: "center" }}
        >
          Preview of {templateDetails?.templateName}
        </Typography>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", color: "#007AFF", textAlign: "center" }}
        >
         {templateDetails?.status}
        </Typography>
        {/* Template Message Section */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            backgroundColor: "#e1ffc7", // Background for template preview
            padding: "10px",
            borderRadius: "8px",
          }}
        >
      
          <div
            style={{
              flex: 1,
              padding: "10px",
              background: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              wordWrap: "break-word",
              wordBreak: "break-word",
              position: "relative",
            }}
          >
            {/* Preview Image */}
            {templateDetails?.previewImageUrl && (
              <img
                src={templateDetails?.previewImageUrl}
                alt="Template Preview"
                style={{
                  width: "100%",
                  height:"250px",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
            )}

            {/* Template Body */}
            <Typography variant="body2" style={{ fontSize: "14px", color: "#333" }}>
              {templateDetails?.templateBody}
            </Typography>

            {/* Footer Section */}
            <div
              style={{
                bottom: "5px",
                left: "5px",
                fontSize: "12px",
                color: "#999",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className="footerText">{templateDetails?.footerText || "Footer Information"}</p>
              <p className="footerText">
                {templateDetails?.timestamp || "8/20/2020"}
              </p>
            </div>

            {/* Divider Between Button Rows */}
            <Divider style={{ margin: "10px 0" }} />

            {/* Buttons Section */}
            <Box style={{ marginTop: "10px", display: "flex", flexDirection: "column" }}>
              {templateDetails?.button1Text && (
                <>
                  <button
                    type="button"
                    className="whatsappButton"
                    onClick={handleConfirmTemplate}
                  >
                    {templateDetails?.button1Text}
                  </button>
                  <Divider style={{ margin: "10px 0" }} />
                </>
              )}

              {templateDetails?.button2Text && (
                <button
                  type="button"
                  className="whatsappButton"
                  onClick={handleCancelTemplate}
                >
                  {templateDetails?.button2Text}
                </button>
              )}
            </Box>
          </div>
        </div>

        {/* Action Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            gap: "10px",
          }}
        >
          <Button
            onClick={handleConfirmTemplate}
            variant="contained"
            color="primary"
            style={{ flex: 1 }}
            disabled={disabled}
          >
            Confirm
          </Button>
          <Button
            onClick={() => setShowPopup(false)}
            variant="outlined"
            color="secondary"
            style={{ flex: 1 }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MessageTemplateModal;
