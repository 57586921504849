import React from "react";
import { Avatar, Typography, Button, Box, Divider } from "@mui/material";
import './template.css';
import { getStatusIcon } from "./statusIcon";
import moment from "moment";

const TemplateMessage = ({ templateDetails, onButtonClick1, onButtonClick2 }) => {

  const formatTimestamp = (dateTime) => {
    const isNumeric = /^\d+$/.test(dateTime); // Check if the string contains only digits
    return moment(
      isNumeric ? Number(dateTime) : dateTime // Convert numeric string to a number; use ISO string as-is
    ).format('h:mm A');
  };

  
  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "row", alignItems: "center" }}>
      <div
        style={{
          flex: 1,
          padding: "10px",
          background: "#fff",
          borderRadius: "8px",
          position: "relative",
          wordWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        {/* Preview Image */}
        {templateDetails?.previewImageUrl && (
          <img
            src={templateDetails?.previewImageUrl}
            alt="Template Preview"
            style={{
              width: "100%",
              borderRadius: "8px",
              marginBottom: "10px",
            }}
          />
        )}

        {/* Template Body */}
        <Typography variant="body2" style={{ fontSize: "14px", color: "#333" }}>
          {templateDetails?.templateBody}
        </Typography>

        {/* Footer Section */}
        <div
          style={{
            bottom: "5px",
            left: "5px",
            fontSize: "12px",
            color: "#999",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p className="footerText">{templateDetails?.footerText || "Footer Information"}</p>
          <p className="footerText"> {formatTimestamp(templateDetails?.timestamp)}</p>
        </div>

        <hr />

        {/* Buttons */}
        <Box style={{ marginTop: "10px", display: "flex", flexDirection: "column" }}>
          {templateDetails?.button1Text && (
            <>
              <button type="button" className="whatsappButton" onClick={onButtonClick1}>
                {templateDetails?.button1Text}
              </button>
              <Divider style={{ margin: "10px 0" }} />
            </>
          )}

          {templateDetails?.button2Text && (
            <button type="button" className="whatsappButton" onClick={onButtonClick2}>
              {templateDetails?.button2Text}
            </button>
          )}
        </Box>

        {/* Status Icon */}
        <div>
          <div style={{ float: "right", paddingTop: "6px" }}>
            {getStatusIcon(templateDetails?.status)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateMessage;
