import React, { useState, useEffect, useRef } from 'react'
import './Stores.css'
import { Typography, useTheme, useMediaQuery, Box, InputLabel, MenuItem, FormControl, Select, TextField, InputAdornment, Dialog, DialogContent, Divider, Autocomplete, Stack } from '../MUIcomponents/index'
import { HeaderFontSize_32, contentFontSize_16, HeaderNavigationFontSize_20, contentFontsize_14, contentFontsize_30, contentFontSize_20 } from '../Typography/index'
import CustomButton from '../Commoncomponents/button/index'
import Whatsapp from '../../Utils/Images/Whatsapp.png'
import download from '../../Utils/Images/download.png'
import downloadUpper from '../../Utils/Images/downloadUpper.png'
import storeTemplate from '../../Utils/Images/storeTemplate.svg'
import pin from '../../Utils/Images/pin.png'
import SearchIcon from '../../Utils/Images/SearchIcon.png'
import { Checkbox } from '../MUIcomponents/index';
import BasicTable from './BasicTable'
import Filter from './Filter'
import GroupChat from './GroupChat'
import AddStore from './AddStore'
import { useDispatch, useSelector } from 'react-redux';
import { GetStoreList, SearchStore,filterStoreList,filterStore,GetStoreSearchList, GetStoreDetails} from '../../graphql/actions';
import {setCheckBoxDetails,setClearFilterData,setClearSearchData,setFilterStoreBoolean, setSearchStoreData} from '../../redux/slices/marketingSlice'
import UpdateFilter from './UpdateFilter'
import axios from 'axios';
import * as XLSX from 'xlsx';
import { CircularProgress } from '@mui/material'
export default function Stores() {
  
  const [age, setAge] = useState('');
  const [select1, setSelect1] = useState('')
  const [select2, setSelect2] = useState('')
  const [select3, setSelect3] = useState('')
  const [select4, setSelect4] = useState([])
  const [select5, setSelect5] = useState('')
  const [select6, setSelect6] = useState('')
  const [select7, setSelect7] = useState('')
  const [select8, setSelect8] = useState('')
  const [select9, setSelect9] = useState('')

  useEffect(()=>{
    dispatch(GetStoreList())
    setSelect2('')
 },[])
  const [checked, setChecked] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open12, setOpen12] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [checkedState, setCheckedState] = React.useState([]);
  const [openUpdateFilter,setOpenUpdateFilter]=React.useState(false)


  const [time,setTime]= useState(false)

  const [count,setCount] = useState()
  const checkedCount = Object?.values(checkedState)?.filter(Boolean)?.length;

  
  const handleUpdateFilterClose=()=>{
    setOpenUpdateFilter(false)
    setAge('')
  }

  const dispatch = useDispatch();
  const { data, error, addStoreDataSuccess,filterStoreData,filterStoreListData,searchStoreError,datasearch,searchStoreData} = useSelector((state) => state.marketingSlice);

const loading = useSelector(state => state.marketingSlice?.loading);
//console.log("loading",loading)

const handleDownloadExcel = () => {
  if (!datasearch || !datasearch?.payload?.listStores || datasearch?.payload?.listStores?.length === 0) {
    console.error('No data available for download');
    return;
  }

  // Define the headings
  const headings = [
    ['Store Name', 'Business Type', 'Location', 'Email', 'Mobile', 'Status']
  ];

  // Map the data to the format for Excel
  const dataForExcel = datasearch?.payload?.listStores?.map((store) => [
    store.storeName || '',
    store.businessType || '',
    store.district || '',
    store.emailId || '',
    store.mobileNo || '',
    store.status || '',
  ]);

  


  // Check if dataForExcel is properly defined
  if (!Array.isArray(dataForExcel)) {
    console.error('Error processing data for Excel');
    return;
  }

  // Combine headings and data
  const worksheetData = [...headings, ...dataForExcel];

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Create a workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Stores');

  // Generate the Excel file
  XLSX.writeFile(workbook, 'store_list.xlsx');
};
const handleDownloadExcelHeading = () => {
  // Define the headings
  const headings = [
    ['Store Name', 'Business Type', 'district', 'city', 'state', 'email','mobileNo','status']
  ];

  // Combine headings (no data is added)
  const worksheetData = [...headings];

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Create a workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Stores');

  // Generate the Excel file
  XLSX.writeFile(workbook, 'store_template.xlsx');
};

  useEffect(() => {
    dispatch(GetStoreList());
    dispatch(filterStoreList())
    dispatch(GetStoreSearchList())

  }, []);


  const handleSearch = () => {
    dispatch(setClearFilterData())
    dispatch(SearchStore(select1, select2, select3, select4, select5, inputValue))
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
    dispatch(setClearSearchData())
  },[])

useEffect(()=>{
  if( filterStoreData?.payload?.filterStores?.length > 0 ){
    setSelect1('')
    setSelect2('')
    setSelect3('')
    setSelect4([])
    setSelect5('')
    setInputValue('')
  }

},[filterStoreData])



  // Handles the input field value change (when user types)


  // Handles the selection of an option from the dropdown

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true)
  }

  const handleClose1 = () => {
    setOpen1(false)
  }
  const handleClickOpen12 = () => {
    setOpen12(true)
  }

  const handleClose12 = () => {
    setOpen12(false)
    setOpen13(false)
    window.location.reload();
  }

const handleChangeCheckout1 = (event) => {
  const isChecked = event.target.checked;
  setChecked(isChecked);

  // Update checkedState based on the datasearch payload
  const updatedCheckedState = searchStoreData?.searchStores?.length !== 0 &&  datasearch?.payload?.listStores?.reduce((acc, store) => {
    acc[store.id] = isChecked;
    return acc;
  }, {});
  
  setCheckedState(updatedCheckedState);
  dispatch(setCheckBoxDetails(updatedCheckedState));

};



  const handleChange = (event) => {
    dispatch(setFilterStoreBoolean())
    setAge(event.target.value);
    dispatch(filterStore(event.target.value))
    setOpenUpdateFilter(true)
   
  };

  const handleChange1 = (event) => {
    setSelect1(event.target.value)
  }
  const handleChange2 = (event) => {
    setSelect2(event.target.value)
  }
  const handleChange3 = (event) => {
    setSelect3(event.target.value)
  }
  const handleChange4 = (event) => {
    setSelect4(event.target.value)
  }
  const handleChange5 = (event) => {
    setSelect5(event.target.value)
  }

  const handleChange6 = (event) => {
    setSelect6(event.target.value)
  }
  const handleChange7 = (event) => {
    setSelect7(event.target.value)
  }
  const handleChange8 = (event) => {
    setSelect8(event.target.value)
  }
  const handleChange9 = (event) => {
    setSelect9(event.target.value)
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"))
  const upLgmatch = useMediaQuery(theme.breakpoints.up("lg"));

  const state = [
    { id: 1, label: "Tamil Nadu" },
    { id: 2, label: "kerala" },
    { id: 3, label: "Telugana" }
  ]
  const Distric = [
    { id: 1, label: "Kanyakumari" },
    { id: 2, label: "Trunelveli" },
    { id: 3, label: "Chennai" }
  ]
  const city = [
    { id: 1, label: "Paloor" },
    { id: 2, label: "karungal" },
    { id: 3, label: "Nagarcoil" }
  ]

  const BusinessType = [
    { id: 1, label: "Restaurants" },
        { id: 2, label: "Pet Store" },
        { id: 3, label: "Fish & Meat Store" },
        { id: 4, label: "Supermarket" },
         { id: 5, label: "Bakery" },
        { id: 6, label: "Grocery Store" },
        { id: 7, label: "Home Business" },
        { id: 8, label: "Electronics" },
        { id: 9, label: "Pharmacy" },
        { id: 10, label: "Crafts" },
        { id: 11, label: "Stationary" },
        {id:12,label:"Beauty"}

  ]


  const status = [
    { id: 1, label: 'Uninvited' },
    { id: 2, label: 'Invited' },
    { id: 3, label: 'Accepted' },
    {id:4, label :'Rejected'},
    {id:5, label :'Completed'}
  ]
 


function handleClear (){
  setSelect1('')
  setSelect2('')
  setSelect3('')
  setSelect4([])
  setSelect5('')
  setInputValue('')
  dispatch(GetStoreList())
}


  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_EXCEL_UPLOAD}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('File uploaded successfully');
  
        // Optionally dispatch your action here
        dispatch(GetStoreList());
      } catch (error) {
        console.error('Error uploading file:', error);
  
        // Optionally display an error alert
        alert('Error uploading file. Please try again.');
      }
    }
  };
  const [open13,setOpen13] = useState(false)
  const handleRowClick = (id) => {
    setOpen13(true); // Open the GroupChat
    // Dispatch an action to get the store details for the clicked row
    dispatch(GetStoreDetails(id));
  };

  useEffect(() => {
    dispatch(GetStoreList());
  }, [dispatch]);  


  const length = datasearch?.payload?.listStores?.length

  const sharedTextFieldStyles = {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      borderRadius: "16px",
      backgroundColor: 'white', // Ensuring input area is white

      '& fieldset': {

        borderColor: '#0D75BA',
        borderWidth: '1px', // Adjust this value as needed
      },
      '&.Mui-focused fieldset': {

        borderColor: '#0D75BA',
        borderWidth: '1px', // Adjust this value as needed
      },
    },
    '& .MuiInputBase-input': {
      backgroundColor: 'white',

    },
    '& .MuiAutocomplete-input': {
      padding: '7.5px 15px 7.5px 5px !important',  // Adding the padding for the clear button
    },
  };

  const SearchInputProps = {
    sx: {
      fontSize: matches && '13px',
      height: matches ? "40px" : "48px",
      paddingRight: 0,
    },
  };


  return (
    <>
    <div style={{ padding: matches ? "0px" : "0px 20px" }}>
      <Typography fontSize={HeaderFontSize_32} style={{ fontFamily: "Inter", fontWeight: "600" }}>
        Store Details
      </Typography>
      <div style={{
        marginTop: '20px', display: "flex", justifyContent: 'space-between', alignItems: "center", height: "100%", width: "100%", flexWrap: "wrap", gap: "20px"
      }}>
        <CustomButton color="white" background="#0D75BA" height={matches ? "35px" : "45px"} matchPadding={true} padding={matches ? "10px 30px" : "0px 60px"} borderRadius="10px"  onClick={handleClickOpen}><Typography style={{ textTransform: "capitalize", fontFamily: "Heebo", fontWeight: "500" }} fontSize={contentFontSize_16}>Add new Store</Typography></CustomButton>
        <div style={{ display: "flex", gap: "10px", alignItems: "center", flexWrap: smmatch && "wrap" }}>
          <div className='storeIconPadding' style={{ padding: "5px 20px", cursor: "pointer" }} onClick={handleClickOpen12}>
            <img src={Whatsapp} alt="whatsapp image" width={matches ? "23px" : "29px"} height={matches ? "23px" : "29px"}  />
          </div>
          <div className='storeIconPadding' style={{ padding: "5px 20px" }} onClick={handleDownloadExcel}>
            <img src={download} alt="download image" width={matches ? "23px" : "29px"} height={matches ? "23px" : "29px"}  />
          </div>
          <div className='storeIconPadding' style={{ padding: '5px 20px' }} onClick={handleImageClick}>
      <img
        src={downloadUpper}
        alt='downloadUpper image'
        width={matches ? '23px' : '29px'}
        height={matches ? '23px' : '29px'}
      />
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept='.xls,.xlsx' // Restrict to Excel files
      />
    </div>
          <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
            <div className='storeIconPadding1' style={{ padding: "5px 10px", cursor: "pointer" }} onClick={handleClickOpen1} >
              <img src={pin} alt="pin image" width={matches ? "23px" : "30px"} height={matches ? "23px" : "30px"} />
            </div>
          
            <FormControl fullWidth>

              <Select
                sx={{
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '7px',
                  borderBottomRightRadius: '7px',
                  borderBottomLeftRadius: '0px',
                  height: matches ? "37px" : "42px",
                  width: matches ? "35px" : "40px",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',

                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',
                  },
                }}

                value={age}

                onChange={handleChange}
                renderValue={() => ''}
              >
                {filterStoreListData?.payload?.getFilters.map((item,index) => (
                  <MenuItem key={index} value={item.filterName}>
                    <Typography className='Label' fontSize={contentFontSize_16}>
                      {item.filterName}
                    </Typography>

                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>
        </div>
      </div>
      <div style={{display:"flex",justifyContent:"flex-end",marginTop:"15px"}}>
            <Typography sx={{color:"#0D75BA",fontFamily: "Heebo",fontSize:"14px",fontWeight:"600"}}>
            Download Excel Format
            <img src={storeTemplate} style={{marginLeft:"15px"}}  onClick={handleDownloadExcelHeading}/>
            </Typography>
          </div>

      <div style={{ marginTop: "40px", display: "flex", gap: "20px", alignItems: "center", height: "100%", width: "100%", flexWrap: "wrap", justifyContent: upLgmatch && "space-between" }}>
        <div style={{ display: "flex", gap: "10px", flexDirection: "column", width: smmatch && "100%" }}>
          <Typography className='Label' fontSize={contentFontSize_16}>State</Typography>
          <Box sx={{ minWidth: smmatch ? 1 : 180 }}>
            <FormControl fullWidth>

              <Select
                sx={{
                  borderRadius: "10px",
                  height: matches ? "40px" : "45px",
                  width: smmatch ? "100%" : "250px",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',

                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',
                  },
                }}
                displayEmpty
                value={select1}

                onChange={handleChange1}
                renderValue={(selected) => {

                  if (!selected) {
                    return <Typography className='Label' fontSize={contentFontSize_16}>Select</Typography>;
                  }
                  return <Typography className='Label' fontSize={contentFontSize_16}>{selected}</Typography>;
                }}

              >
                {state.map((item) => (
                  <MenuItem key={item.id} value={item.label} >
                    <Typography className='Label' fontSize={contentFontSize_16}>
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={{ display: "flex", gap: "10px", flexDirection: "column", width: smmatch && "100%" }}>
          <Typography className='Label' fontSize={contentFontSize_16}>District</Typography>
          <Box sx={{ minWidth: smmatch ? 1 : 180 }}>
            <FormControl fullWidth>

              <Select
                sx={{
                  borderRadius: "10px",
                  height: matches ? "40px" : "45px",
                  width: smmatch ? "100%" : "250px",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',

                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',
                  },
                }}
                displayEmpty

                value={select2}

                onChange={handleChange2}
                renderValue={(selected) => {

                  if (!selected) {
                    return <Typography className='Label' fontSize={contentFontSize_16}>Select</Typography>;
                  }
                  return <Typography className='Label' fontSize={contentFontSize_16}>{selected}</Typography>;
                }}

              >
                {Distric.map((item) => (
                  <MenuItem key={item.id} value={item.label}>
                    <Typography className='Label' fontSize={contentFontSize_16}>  {item.label}</Typography>

                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={{ display: "flex", gap: "10px", flexDirection: "column", width: smmatch && "100%" }}>
          <Typography className='Label' fontSize={contentFontSize_16}>City</Typography>
          <Box sx={{ minWidth: smmatch ? 1 : 180 }}>
            <FormControl fullWidth>

              <Select
                sx={{
                  borderRadius: "10px",
                  height: matches ? "40px" : "45px",
                  width: smmatch ? "100%" : "250px",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',

                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0D75BA',
                  },
                }}
                displayEmpty

                value={select3}

                onChange={handleChange3}
                renderValue={(selected) => {
                  if (!selected) {
                    return <Typography className='Label' fontSize={contentFontSize_16}>Select</Typography>;
                  }
                  return <Typography className='Label' fontSize={contentFontSize_16}>{selected}</Typography>;
                }}

              >
                {city.map((item) => (
                  <MenuItem key={item.id} value={item.label}>
                    <Typography className='Label'fontSize={contentFontSize_16}>
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div style={{ display: "flex", gap: "10px", flexDirection: "column", width: smmatch && "100%" }}>
          <Typography className='Label' fontSize={contentFontSize_16}>Status</Typography>

          <FormControl fullWidth>

            <Select
              sx={{
                borderRadius: "10px",
                height: matches ? "40px" : "45px",
                width: smmatch ? "100%" : "250px",
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0D75BA',

                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0D75BA',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0D75BA',
                },
              }}

              displayEmpty
              size={matches ? "small" : "medium"}
              value={select5}

              onChange={handleChange5}
              renderValue={(selected) => {

                if (!selected) {
                  return <Typography className='Label'
                  fontSize={contentFontSize_16}>
                    Select
                    </Typography>;
                }
                return <Typography 
                className='Label'
                fontSize={contentFontSize_16}>
                  {selected}
                  </Typography>;
              }}

            >
              {status.map((item) => (
                <MenuItem key={item.id} value={item.label}>
                  <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>
                    {item.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>

      </div>
      <br/>
     <div style={{ display: "flex", gap: "20px", flexDirection: "row", width: "100%" }}>
  {/* First Column */}
  <div style={{ flex: 1 }}>
    <Typography className='Label' fontSize={contentFontSize_16}>
      BusinessType
    </Typography>
    <Box sx={{ minWidth: smmatch ? 1 : 180 }}>
      <FormControl fullWidth>
        <Select
          // multiple
          sx={{
            borderRadius: "10px",
            height: "45px",
            width: "100%",
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#0D75BA',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#0D75BA',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#0D75BA',
            },
          }}
          displayEmpty
          value={select4}
          onChange={handleChange4}
          renderValue={(selected) => {
           
            return selected
          }}
        >
          {BusinessType.map((item) => (
            <MenuItem key={item.id} value={item.label}>
              <Checkbox checked={select4.indexOf(item.label) > -1} />
              <Typography className='Label' fontSize={16}>
                {item.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  </div>

  {/* Second Column */}
  <div style={{ flex: 1 }}>
    <Typography  className="Label"
  fontSize={contentFontSize_16}>
      Store Name
    </Typography>
    <Stack spacing={2} sx={{ width: 1 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={datasearch?.payload?.listStores || []}
        getOptionLabel={(option) => option?.storeName || ''}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Store Name"
            sx={sharedTextFieldStyles}
            InputProps={{
              ...params?.InputProps,
              ...SearchInputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Stack>
  </div>
</div>


<div style={{ display: "flex", justifyContent: "flex-start", marginTop: "20px", gap: "10px" }}>
  <CustomButton
    color="white"
    background="#0D75BA"
    width="100px"
    height="40px"
    onClick={handleSearch}
  >
    Search
  </CustomButton>
  <CustomButton
    color="white"
    background="#0D75BA"
    width="100px"
    height="40px"
    onClick={handleClear}
  >
    Clear
  </CustomButton>
</div>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%", width: "100%", gap: "20px", marginTop: "20px" }}>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <div>
            <Checkbox
             
              style={{ color: checked? "#0D75BA":"#8F9BBA", padding: "0px 0px" }}
              checked={checked}
              onChange={handleChangeCheckout1}


              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
          <Typography style={{ fontFamily: "DM Sans", fontWeight: "400", color: checked ? "#0D75BA":"#8F9BBA" }} fontSize={HeaderNavigationFontSize_20}>
            Select All
          </Typography>
        </div>
        <Typography style={{ fontFamily: "DM Sans", fontWeight: "400", color: "#8F9BBA" }} fontSize={contentFontsize_14}>
          {checkedCount}/{datasearch?.payload?.listStores?.length !== data?.listStores?.length ? data?.listStores?.length :   datasearch?.payload?.listStores?.length}
        </Typography>
      </div>
      <div >
        <BasicTable checkedState={checkedState} setCheckedState={setCheckedState} setChecked={setChecked} onRowClick={handleRowClick}  />
      </div>

      {open && (<AddStore open={open} handleClose={handleClose} setOpen={setOpen} />)}

      {open1 && (<div><Filter open1={open1} setOpen1={setOpen1} handleClose1={handleClose1}  setAge={setAge}/></div>)

      }
     {(open12 || open13) && (
  <div>
    <GroupChat 
      open12={open12} 
      setOpen12={setOpen12} 
      length={length} 
      handleClose12={handleClose12} 
      open13={open13} 
      setOpen13={setOpen13} 
      checkedCount={checkedCount}
      
    />
  </div>
)}

      {openUpdateFilter&&(<div> <UpdateFilter openUpdateFilter={openUpdateFilter} handleUpdateFilterClose={handleUpdateFilterClose} setAge={setAge}/> </div>)}
     
    </div>
    
    </>
  )
}


