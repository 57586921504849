import React,{useState,useEffect} from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ResponsiveDrawer from './components/ResponsiveDrawer';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import Dashboard from './components/Dashboard/Dashboard';
import Stores from './components/Stores/Stores';
import Template from './components/Templates/Template';
import Report from './components/Reports/Report';
import TemplateElements from './components/Templates/TemplateElements';
import TemplateForm from './components/Templates/TemplateForm';
import TemplateSave from './components/Templates/TemplateSave';
import MessageComponent from './components/MessageComponent';
import ProtectedRoute from './ProtectedRoute';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { getMessageList } from './graphql/actions';
import Chat from './components/Stores/Chat';
import ChatScreen from './components/Stores/Chat';
import InboxScreen from './components/Stores/InboxScreen';

function App() {
   
  useEffect(() => {
    const loader = document.getElementById("global-loader");
    if (loader) {
      loader.style.display = "none";
    }
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        {/* Wrap main app routes in a layout component */}
        <Route path="*" element={<MainApp />} />
      </Routes>
    </Router>
  );
}

function MainApp() {
 
  const dispatch = useDispatch();
  // const socket = io('https://demoserver.nearshopz.com');
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [storeId, setStoreId] = useState('');

  const handleAlert = (message, storeId) => {
    setAlertMessage(message);
    // setStoreId(storeId);
    setAlertOpen(true);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    // Listen for new messages from the server
    socket.on('MessageRecieved', (data) => {
      setAlertOpen(true);
      setAlertMessage("You have a new message");
      setStoreId(data?.storeId);
      handleAlert("You have a new message", storeId);
    });
  
    // Clean up the effect
    return () => {
        socket.off('newMessage');
    };
}, []);


  return (
    <>
     <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
       open={alertOpen} autoHideDuration={20000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    <ResponsiveDrawer>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              {/* <Dashboard /> */}
              <Stores />
            </ProtectedRoute>
          }
        />
        <Route
          path="/store"
          element={
            <ProtectedRoute>
              <Stores />
            </ProtectedRoute>
          }
        />
          <Route
          path="/template"
          element={
            <ProtectedRoute>
              <Template />
            </ProtectedRoute>
          }
        />
           <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <ChatScreen />
            </ProtectedRoute>
          }
        />
         <Route path="/chat/:storeId"   element={
            <ProtectedRoute>
              <InboxScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template/templateForm"
          element={
            <ProtectedRoute>
              <TemplateForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template/templateElements"
          element={
            <ProtectedRoute>
              <TemplateElements />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template/templateSave"
          element={
            <ProtectedRoute>
              <TemplateSave />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/report"
          element={
            <ProtectedRoute>
              <Report />
            </ProtectedRoute>
          }
        /> */}
        {/* Redirect to Dashboard or login if an invalid route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ResponsiveDrawer>
    </>
  );
}

export default App;