// import { TextField, Typography } from "@mui/material";
// import React, { useState } from "react";
// import { contentFontSize_16 } from "../Typography/index";
// //import CustomTextField from "../Commoncomponents/input"
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import Plus from "../../Utils/Images/Plus.svg";
// import CustomButton from "../Commoncomponents/button/index";
// import TemplateElements from "./TemplateElements";
// import { useNavigate } from "react-router-dom";
// function TemplateForm() {
// const [text, setText] = useState("");

// function handleText(event) {
//   setMedia("");
//   setText(event.target.value);
//   console.log("text", text);
// }
// const [media, setMedia] = useState("");
// function handleMedia(event) {
//   setText("");
//   setMedia(event.target.value);
// }
// const [next, setNext] = useState(false);
// const nav = useNavigate();
// function handleNext() {
//   //nav("/template/templateElements")
//   setNext(true);
//   console.log("next", next);
// }
//   return (
//     <>
// <div>
//   {!next ? (
//     <>
//       <div style={{ marginTop: "80px",padding:"20px" }}>
//         <Typography
//           variant="subtitle1"
//           gutterBottom
//           sx={{
//             fontFamily: "Roboto",
//             fontWeight: "600",
//             fontSize: { contentFontSize_16 },
//           }}
//         >
//           Template Name
//         </Typography>
//         <TextField variant="outlined" fullWidth size="normal" />
//       </div>
//       <div style={{ marginTop: "10px",padding:"20px" }}>
//         <Typography
//           variant="subtitle1"
//           gutterBottom
//           sx={{
//             fontFamily: "Roboto",
//             fontWeight: "600",
//             fontSize: { contentFontSize_16 },
//           }}
//         >
//           Language
//         </Typography>
//         <TextField
//           //placeholder="Enter Your Password"
//           type="password"
//           variant="outlined"
//           fullWidth
//         />
//       </div>
//       <div style={{ marginTop: "20px",padding:"20px" }}>
//         <FormControl>
//           <FormLabel
//             id="demo-row-radio-buttons-group-label"
//             sx={{
//               fontFamily: "Roboto",
//               fontWeight: "600",
//               fontSize: { contentFontSize_16 },
//               color: "black !important",
//             }}
//           >
//             Message Header
//           </FormLabel>
//           <RadioGroup
//             row
//             aria-labelledby="demo-row-radio-buttons-group-label"
//             name="row-radio-buttons-group"
//           >
//             <FormControlLabel
//               value="text"
//               control={<Radio />}
//               label="Text"
//               onChange={handleText}
//             />
//             <FormControlLabel
//               value="media"
//               control={<Radio />}
//               label="media"
//               onChange={handleMedia}
//             />
//           </RadioGroup>
//         </FormControl>
//       </div>
//       {text && (
//         <div style={{padding:"20px"}}>
//           <TextField variant="outlined" fullWidth size="normal" />
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-end",
//               marginTop: "10px",
//             }}
//           >
//             <img src={Plus} />
//             <Typography
//               style={{
//                 textAlign: "right",
//                 color: "#0D75BA",
//                 fontFamily: "Roboto",
//                 fontWeight: "600",
//                 fontSize: { contentFontSize_16 },
//               }}
//             >
//               Add variables
//             </Typography>
//           </div>
//         </div>
//       )}
//       {media && <div>hidden</div>}
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-end",
//           marginTop: "0px",
//           padding:"0px 10px 0px 0px "
//         }}
//       >
//         <CustomButton
//           background="#0D75BA"
//           borderRadius="10px"
//           width="90px"
//           onClick={handleNext}
//         >
//           <Typography sx={{ textTransform: "capitalize" }}>
//             Next
//           </Typography>
//         </CustomButton>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           marginTop: "0px",
//           padding:"0px 10px 0px 0px "
//         }}
//       >
//         <Typography>Step 1 of 3</Typography>
//       </div>
//     </>
//   ) : (
//     <TemplateElements />
//   )}
// </div>
//     </>
//   );
// }

// export default TemplateForm;

import React, { useDeferredValue, useEffect, useState } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import Edit from "../../Utils/Images/Edit.svg";
import Delete from "../../Utils/Images/Delete.svg";
import Plus from "../../Utils/Images/Plus.svg";
import CustomButton from "../Commoncomponents/button/index";
import CustomCard from "../Commoncomponents/card/index";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CustomDialog from "../Commoncomponents/dialog";
import Food from "../../Utils/Images/Food.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  resetReturnFlag,
  setDeleteTemplateSuccess,
  setReturnFlag,
  updateTextField,
} from "../../redux/slices/marketingSlice";
import { message } from "antd";
import {
  getTemplateDelete,
  getTemplateList,
  getTemplateSave,
} from "../../graphql/actions";
import {
  contentFontsize_14,
  contentFontSize_16,
  contentFontSize_16_xs_10,
  contentFontSize_18,
  contentFontsize_24,
  contentFontsize_28,
} from "../Typography";
export default function TemplateSave() {
  const theme = useTheme();
  const isLessThan350 = useMediaQuery("(max-width:350px)");
  const smMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMatch = useMediaQuery(theme.breakpoints.down("md"));
  const lgMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const steps = [
    "Create New Template",
    "Add Template Elements",
    "Preview Template",
  ];
  const nav = useNavigate();
  const location = useLocation();
  const dataArray = location?.state?.dataArray;
  console.log("arrayssss", dataArray);


  function handleModalOpen() {
    setDialogOpen(true);
  }


  function handleClose() {
    setDialogOpen(false);
    setOpen(false);
    nav("/template");
    setDeletes(false);
  }
  const savedValuess = JSON.parse(localStorage.getItem("secondValues"));
  const [previousSave, setPreviousSave] = useState(
    savedValuess ? savedValuess[3]?.content : ""
  );
  //const dispatch = useDispatch();
  const returnFlag = useSelector((state) => state.marketingSlice.returnFlag);
  const handlePrevious = () => {
    // Get the current value of returnFlag

    // Reset the flag before navigating back to the previous page
    dispatch(setReturnFlag(true));

    // Navigate back to the previous template page with the returnFlag in the state
    nav("/template/templateElements", {
      state: { previousSave: previousSave, returnFlag: returnFlag },
    });
  };
  const buttonLabels = dataArray[4]?.value?.split(" / ");
  console.log("labels", buttonLabels);
  useEffect(() => {
    getTemplateList();
  }, []);
  const dispatch = useDispatch();
  const listData = useSelector((state) => state.marketingSlice?.listData);
  const saveData = useSelector((state) => state.marketingSlice?.saveData);
  const saveError = useSelector((state) => state.marketingSlice?.saveError);
  console.log("data", listData?.listTemplates);
  const [deletes, setDeletes] = useState(false);
  const deleteData = useSelector((state) => state.marketingSlice?.deleteData);
  const saveSuccess = useSelector((state) => state.marketingSlice?.saveSuccess);
  // const [deletes, setDeletes] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null); // For tracking the item to delete

  const handleDialog = (id) => {
    setSelectedItemId(id); // Set the ID of the item you want to delete
    setDeletes(true); // Open the dialog
  };
  // function handleClose() {
  //   setDeletes(false);
  // }
  function handleDeletes(id) {
    dispatch(getTemplateDelete(id));

    setDeletes(false);
  }
  const deleteTemplateSuccess = useSelector(
    (state) => state.marketingSlice.deleteTemplateSuccess
  );
  console.log("success", deleteTemplateSuccess);

  useEffect(() => {
    if (deleteTemplateSuccess) {
      setDeletes(false);
      message.success({
        content: "Template Deleted Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });

      setTimeout(() => {
        // Reload the list or perform any other actions
        dispatch(setDeleteTemplateSuccess());
        dispatch(getTemplateList());
        // Reset the success flag after handling it
      }, 1500);
    }
  }, [deleteTemplateSuccess]);
  const [open, setOpen] = useState(false);
  function handleSave() {
    const templateName = dataArray[0].value;
    const language = dataArray[1].value;
    const headerType = dataArray[2].type;
    const headerContent = dataArray[2].content;
    const headerVariable = dataArray[2].variable;
    const headerVariableType = dataArray[2].variableType;
    const body = dataArray[3].content;
    const bodyName = dataArray[3].name;
    const bodyType = dataArray[3].type;
    const button = dataArray[4].value;
    const footer = dataArray[5].value;
    const mediaId = dataArray[6].id;
    const h = dataArray[6].value;
    console.log("handleSave", templateName,
      language,
      headerType,
      headerContent,
      headerVariable,
      headerVariableType,
      mediaId,
      body,
      bodyName,
      bodyType,
      button,
      footer,
      h)
    dispatch(
      getTemplateSave(
        templateName,
        language,
        headerType,
        headerContent,
        headerVariable,
        headerVariableType,
        mediaId,
        body,
        bodyName,
        bodyType,
        button,
        footer,
        h
      )
    );

    localStorage.removeItem("secondValues");
  }

  useEffect(() => {
    if (saveData || saveError) {
      setOpen(true);
      setTimeout(() => {
        dispatch(getTemplateList());
      }, 1000);
    }
  }, [saveData, saveError]);

  function hanldeBack() {
    nav("/template");
    dispatch(getTemplateList());
  }

  const isImage = /\.(jpg|jpeg|)$/i.test(dataArray[6].value);
  console.log("isImage", isImage);
  const isVideo = /\.(mp4)$/i.test(dataArray[6].value);
  console.log("deleteData", deleteData);
  const isPdf = /\.(pdf)$/i.test(dataArray[6].value);
  const leftSideContent = (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        position: "relative",
        zIndex: 0,
        padding: "20px",
        minHeight: "150vh",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontFamily: "Inter",
          fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Available Template
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: smMatch ? "60%" : mdMatch ? "130px" : "300px",
            marginTop: isLessThan350 ? "30px" : "120px",
            textAlign: "center",
            textTransform: "capitalize",
            height: "50px",
            borderRadius: "10px",
          }}
        >
          Create New Template
        </Button>
      </div>
      {listData?.listTemplates?.map((item) => (
       
        <div
          key={item.id}
          style={{
            display: "flex",
            flexDirection: lgMatch ? "column" : "row",
            justifyContent: mdMatch ? "center" : "space-evenly",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "0px",
            }}
          >
            <>
              <CustomCard
                width="300px"
                height="100%"
                padding="16px 16px"
                border="1px solid #0D75BA"
                borderRadius="10px"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography
                      sx={{ fontWeight: "500", fontFamily: "Heebo" }}
                      fontSize={contentFontSize_16}
                    >
                      {item?.templateName}
                    </Typography>
                  </div>
                  <div
                    style={{
                      marginTop: lgMatch ? "0px" : "0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Edit}
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleEdit(item.id)} // Pass the id onClick
                    />
                    <img
                      src={Delete}
                      style={{ cursor: "pointer", marginLeft: "20px" }}
                      onClick={() => handleDialog(item.id)} // Open the dialog with item ID
                    />
                  </div>
                </div>
              </CustomCard>
            </>
          </div>
        </div>
      ))}

      {deletes && selectedItemId && (
        <Dialog open={deletes}>
          <DialogContent>
            <div
              style={{
                height: "100%",
                width: "100%",
                padding: mdMatch ? "10px" : "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <Typography
                  fontSize={contentFontSize_16_xs_10}
                  className="chatFontHeebo400Color"
                >
                  Are you sure you want to delete this Template?
                </Typography>

                <div
                  style={{
                    display: "flex",
                    gap: mdMatch ? "10px" : "15px",
                    textAlign: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography
                    fontSize={contentFontSize_16_xs_10}
                    onClick={handleClose}
                    className="chatFontHeebo400Color"
                    style={{ cursor: "pointer" }}
                  >
                    Cancel
                  </Typography>
                  <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
                  <Typography
                    fontSize={contentFontSize_16_xs_10}
                    onClick={() => handleDeletes(selectedItemId)}
                    className="chatFontHeebo400Red"
                    style={{ cursor: "pointer" }}
                  >
                    Delete
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );

  return (
    <>
      <div>
        <Typography
          sx={{ fontWeight: "600", fontFamily: "Inter", fontSize: "28px" }}
        >
          Template
        </Typography>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            border: "1px solid #ccc",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 25px 5px",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "150px",
              left: 0,
              right: 0,
              borderBottom: "1px solid #7C7B7B",
              zIndex: 1,
            }}
          />

          {isLessThan350 ? (
            <>
              <CustomDialog
                open={dialogOpen}
                onClose={handleClose}
                top="20px"
                right="20px"
              >
                <DialogContent>{leftSideContent}</DialogContent>
              </CustomDialog>
            </>
          ) : (
            <div style={{ width: "30%" }}>{leftSideContent}</div>
          )}

          <div style={{ flex: 1, padding: "0px" }}>
            <div style={{ padding: "20px" }}>
              <Box sx={{ width: "100%", marginBottom: "0px" }}>
                <Stepper activeStep={2} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
            <div style={{ padding: "0px 10px 0px 20px" }}>
              {isLessThan350 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalOpen}
                  >
                    Show Templates
                  </Button>
                </div>
              )}
              <>
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomCard padding="40px" width="70%" boxShadow="none">
                    <div className="container">
                      {isImage ? (
                        <div className="image-container">
                          <img
                            src={dataArray[6].value}
                            alt="Food"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "17px",
                            }}
                          />
                        </div>
                      ) : isVideo ? (
                        <div className="image-container">
                          <video
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "15px",
                            }}
                            controls
                          >
                            <source src={dataArray[6].value} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : isPdf ? (
                        <div>
                          <iframe
                            src={dataArray[6].value}
                            width="100%"
                            height="500px"
                          />
                        </div>
                      ) : null}

                      <div className="content">
                        {dataArray[2].content  && (
                          <Typography
                            sx={{ fontFamily: "Roboto", fontWeight: "700" }}
                            fontSize={contentFontsize_24}
                          >
                            {dataArray[2].content},
                          </Typography>
                        )}
                         {dataArray[3].content && (

                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontWeight: "400",
                              marginTop: "15px",
                              textAlign: "justify",
                              fontWeight: dataArray[3].NeedBold
                                ? "700"
                                : "normal",

                              fontStyle: dataArray[3].needItalic
                                ? "italic"
                                : "normal",
                            }}
                            fontSize={contentFontSize_18}
                          >
                            {dataArray[3].content}
                          </Typography>
                        )}
                      </div>
                      <div
                        className="buttons"
                        style={{
                          display: "flex",
                          gap: "15px",
                          marginTop: "20px",
                        }}
                      >
                        {buttonLabels?.map((label, index) => (
                          <CustomButton
                            key={index}
                            width="200px"
                            height="40px"
                            borderRadius="15px"
                            color="#0D75BA"
                            background="white"
                            variant="outlined"
                            border="5px solid #0D75BA"
                            borderColor="#0D75BA"
                            style={{ margin: "5px" }}
                          >
                            <Typography
                              sx={{
                                color: "#0D75BA",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {label}
                            </Typography>
                          </CustomButton>
                        ))}
                      </div>
                      <div className="footer" style={{ marginTop: "20px" }}>
                        Marketing Team Nearshopz
                      </div>
                    </div>
                  </CustomCard>
                </div>
              </>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "200px",
                  padding: "0px 10px 0px 0px",
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <div>
                    <CustomButton
                      background="#0D75BA"
                      borderRadius="10px"
                      width="90px"
                      onClick={handlePrevious}
                    >
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Previous
                      </Typography>
                    </CustomButton>
                  </div>
                  <div>
                    <CustomButton
                      background="#0D75BA"
                      borderRadius="10px"
                      width="90px"
                      onClick={handleSave}
                    >
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Save
                      </Typography>
                    </CustomButton>
                  </div>
                  {open && (
                    <CustomDialog
                      open={open}
                      onClose={handleClose}
                      top="20px"
                      right="20px"
                      width="350px"
                      height="600px"
                      borderRadius="25px"
                    >
                      <DialogTitle>
                        <Typography
                          sx={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "26px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Congratulations!
                        </Typography>
                      </DialogTitle>
                      <DialogContent sx={{ padding: "10px 20px 10px 55px" }}>
                        <Typography
                          sx={{
                            color: "black",
                            fontWeight: "400",
                            fontSize: "16px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Template {dataArray[0].value} {""}
                          has been successfully created.
                        </Typography>
                      </DialogContent>
                      <DialogActions
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <CustomButton
                          width="120px"
                          height="40px"
                          background="#0D75BA"
                          borderRadius="5px"
                          onClick={hanldeBack}
                        >
                          <Typography sx={{ textTransform: "capitalize" }}>
                            Close
                          </Typography>
                        </CustomButton>
                      </DialogActions>
                    </CustomDialog>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                padding: "0px 10px 0px 0px",
              }}
            >
              <Typography>Step 3 of 3</Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
