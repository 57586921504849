import { gql } from "@apollo/client";

export const GET_LOGIN = gql`
  mutation getLogin($email: String!, $password: String!, $deviceId: String) {
    login(email: $email, password: $password, deviceId: $deviceId) {
      userId
      accessToken
      refreshToken
    }
  }
`;

export const GET_LOGOUT = gql`
  mutation getLogout($refreshToken: String) {
    logout(refreshToken: $refreshToken)
  }
`;

export const TEMPLATE_SAVE = gql`
  mutation getTemplateSave($input: TemplateInput!) {
    saveTemplate(input: $input) {
      id
      templateName
      whatsAppTemplateId
      language
      header {
        type
        content
        variables {
          name
          type
        }
      }
      body
      bodyVariables {
        name
        type
      }
      button
      footer
    }
  }
`;

// export const EDIT_TEMPLATE = gql`
// mutation getTemplateEdit($input:TemplateInput!){
//    editTemplate(input:$input){
//    id
//    templateName
//    language
//    header{
//     type
//     content
//     variables{
//     name
//     type
//     }
//    }
//   body
//   bodyVariables{
//   name
//   type
//   }
//   button
//   footer
//    }
// }
// `

export const ADD_STORE = gql`
  mutation AddStore(
    $storeName: String!
    $businessType: [String]!
    $state: String!
    $district: String!
    $city: String!
    $mobileNo: String!
    $whatsappNo: String!
    $altMobileNo: String
    $emailId: String
  ) {
    addStore(
      storeName: $storeName
      businessType: $businessType
      state: $state
      district: $district
      city: $city
      mobileNo: $mobileNo
      whatsappNo: $whatsappNo
      altMobileNo: $altMobileNo
      emailId: $emailId
    ) {
      id
      storeName
      businessType
      state
      district
      city
      mobileNo
      whatsappNo
      altMobileNo
      emailId
    }
  }
`;

export const DELETE_STORE = gql`
  mutation DeleteStore($storeId: ID!) {
    deleteStore(storeId: $storeId) {
      id
      storeName
      businessType
      state
      district
      city
      mobileNo
      whatsappNo
      altMobileNo
      emailId
      status
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation UpdateStore(
    $storeId: ID!
    $storeName: String
    $businessType: [String]
    $state: String
    $district: String
    $city: String
    $mobileNo: String
    $whatsappNo: String
    $altMobileNo: String
    $emailId: String
    $status: String
  ) {
    updateStore(
      storeId: $storeId
      storeName: $storeName
      businessType: $businessType
      state: $state
      district: $district
      city: $city
      mobileNo: $mobileNo
      whatsappNo: $whatsappNo
      altMobileNo: $altMobileNo
      emailId: $emailId
      status: $status
    ) {
      id
      storeName
      businessType
      state
      district
      city
      mobileNo
      whatsappNo
      altMobileNo
      emailId
      status
    }
  }
`;

export const SAVE_FILTER = gql`
  mutation SaveFilter(
    $filterName: String!
    $state: [String]
    $district: [String]
    $city: [String]
    $businessType: [String]
    $storeName: String
  ) {
    saveFilter(
      filterName: $filterName
      state: $state
      district: $district
      city: $city
      businessType: $businessType
      storeName: $storeName
    ) {
      filterName
      state
      district
      city
      businessType
      storeName
    }
  }
`;
export const EDIT_TEMPLATE = gql`
  mutation editTemplate(
    $id: ID!
    $templateName: String
    $language: String
    $header: HeaderInput
    $body: String
    $bodyVariables: [VariableInput]
    $button: String
    $footer: String
  ) {
    editTemplate(
      id: $id
      templateName: $templateName
      language: $language
      header: $header
      body: $body
      bodyVariables: $bodyVariables
      button: $button
      footer: $footer
    ) {
      id
      templateName
      language
      header {
        type
        content
        variables {
          name
          type
        }
      }
      body
      bodyVariables {
        name
        type
      }
      button
      footer
    }
  }
`;


export const GET_NEW_ACCESS_TOKEN = gql`
  mutation getNewAccessToken($refreshToken: String) {
    getNewAccessToken(refreshToken: $refreshToken) {
      userId
      accessToken
      refreshToken
      verification
    }
  }
`;

export const TEMPLATE_DELETE = gql`
  mutation deleteTemplate($id: ID!) {
    deleteTemplate(id: $id) {
      id
      templateName
      language
      header {
        type
        content
        variables {
          name
          type
        }
      }
      body
      bodyVariables {
        name
        type
      }
      button
      footer
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($file: Upload) {
    uploadImage(file: $file) {
      imageUrl
      mediaId
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($input: WhatsAppMessage!) {
    sendMessage(input: $input)
  }
`;

export const FILTER_DELETE = gql`
  mutation deleteFilter($id: ID!) {
    deleteFilter(id: $id) {
      id
      filterName
      state
      district
      city
      businessType
      storeName
    }
  }
`;

export const SEND_TEMPLATE = gql`
  mutation sendTemplate($input: WhatsAppMessage!) {
    sendTemplate(input: $input)
  }
`;

export const SEND_MESSAGE_TO_ALL = gql`
mutation sendMessagesToAll($input:  WhatsAppMessageType!){
     sendMessagesToAll(input:$input)
      
     
}
`

export const LIST_MESSAGES = gql`
query getMessages($storeId:ID){
getMessages(storeId:$storeId)
  {
    storeId
    messages{
      text
      sentBy
       dateTime
        wamid
      status
     messageType
      templateId
      {
        header{
          content
          imageUrl
        }
        body
        button
        footer
        mediaId
        
      }
    }
  }
}`
