import React, { useEffect, useState } from 'react';
import { Avatar, Box, Typography, List, ListItem, Divider, Badge } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMessageList, updateMessageList } from '../../graphql/actions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../Utils/Loader.gif';
import InboxScreen from './InboxScreen';
import { io } from 'socket.io-client';
import { getStatusIcon } from './statusIcon';

const socket = io(`${process.env.REACT_APP_SOCKET_URL}`);

const ChatScreen = () => {
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [storeId, setStoreId] = useState(false);

  const nav = useNavigate();
  const dispatch = useDispatch();

  // Fetch messages on component mount
  useEffect(() => {
    dispatch(getAllMessageList());
  }, [dispatch]);

  const getRandomColor = () => {
    const colors = [
      '#5f190a', '#b2ad07', '#7a0280', '#800211', '#346a04', '#083395', '#088295', '#539508', '#8c2607', '#7c202c',
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Retrieve message data from Redux store
  const AllMsgData = useSelector((state) => state?.marketingSlice?.AllMsgData);
  const AllMsgLoading = useSelector((state) => state?.marketingSlice?.AllMsgLoading);

  // Listen for new messages from the server
  useEffect(() => {
    socket.on('newMessage', (data) => {
      const existingStoreIndex = AllMsgData?.getAllMessages?.findIndex(
        (conversation) => conversation.storeId === data.storeId
      );

      let updatedMessages;
      if (existingStoreIndex >= 0) {
        // Update the existing conversation
        updatedMessages = AllMsgData?.getAllMessages.map((conversation, index) =>
          index === existingStoreIndex
            ? {
              ...conversation,
              lastMessage: data.messages[data.messages.length - 1],
              messages: data.messages,  // Update the entire message list
            }
            : conversation
        );
      } else {
        // Add a new conversation
        const newConversation = {
          storeName: data.storeId, // Assuming storeId maps to storeName
          storeId: data.storeId,
          lastMessage: data.messages[data.messages.length - 1],
          messages: data.messages,  // Store the full list of messages
        };
        updatedMessages = [...AllMsgData?.getAllMessages, newConversation];
      }

      // Dispatch the action with the updated array
      dispatch(updateMessageList(updatedMessages));
    });

    return () => {
      socket.off('newMessage');
    };
  }, [AllMsgData, dispatch]);

  // const formatTimestamp = (timestamp) => moment(Number(timestamp)).format('MM/DD/YYYY h:mm A');


  const handleClose12 = () => {
    setOpen12(false);
    setOpen13(false);
    // window.location.reload();
  };

  const handleChatClick = (storeId) => {
    setOpen12(true);
    setStoreId(storeId);
  };


  return (
    <div className="inbox-screen">
    <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 20px',
    borderBottom: '2px solid #ddd',
    backgroundColor: '#f5f5f5',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px 8px 0 0',
  }}
>
  <Typography
    variant="h5"
    sx={{
      fontWeight: '600',
      color: '#333',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    }}
  >
    Chats
  </Typography>
</Box>

      <List>
        {AllMsgLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
            <img src={Loader} alt="Loading..." />
          </div>
        ) : (
          AllMsgData?.getAllMessages?.map((conversation, index) => {
            const { storeName, lastMessage, storeId, messages } = conversation;
            const { text, status, dateTime, messageType, templateId } = lastMessage;
            const unreadCount = messages?.filter((msg) => msg.status !== 'read' && msg.sentBy === 'store')?.length;

            return (
              <React.Fragment key={index}>
                <ListItem button onClick={() => handleChatClick(storeId)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px', backgroundColor: 'white', borderRadius: '8px', marginBottom: '10px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', transition: 'background-color 0.3s ease' }}>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Badge
      color="error"
      badgeContent={unreadCount > 0 ? unreadCount : null}
      overlap="circular"
      sx={{ marginRight: '15px' }}
    >
      <Avatar sx={{ backgroundColor: getRandomColor() }}>
        {storeName[0].toUpperCase()}
      </Avatar>
    </Badge>
    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', flex: 1 }}>
    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
      {storeName}
    </Typography>

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
  {/* Status Icon to the Left of the Message */}
  <Box sx={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
    {getStatusIcon(status)}
  </Box>

  {/* Message Text */}
  <Typography variant="body2" sx={{ color: '#777', fontSize: '14px' }}>
    {messageType === 'template' ? templateId?.body : text}
  </Typography>
</Box>


  </Box>
  </Box>

  {/* Status and Date/Time Section on the Right */}
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
   
    {/* Display date and time, with date on top and time below */}
    <Typography variant="caption" sx={{ color: '#888', fontSize: '12px', textAlign: 'right' }}>
      {moment(Number(dateTime)).format('MM/DD/YYYY')}
    </Typography>
    <Typography variant="caption" sx={{ color: '#888', fontSize: '12px', textAlign: 'right' }}>
      {moment(Number(dateTime)).format('h:mm A')}
    </Typography>
  </Box>
</ListItem>
<Divider />

                <Divider />
              </React.Fragment>
            );
          })
        )}
      </List>

      {(open12 || open13) && (
        <InboxScreen
          open12={open12}
          setOpen12={setOpen12}
          length={1}
          handleClose12={handleClose12}
          open13={open13}
          setOpen13={setOpen13}
          checkedCount={1}
          storeID={storeId}
        />
      )}
    </div>
  );
};

export default ChatScreen;
