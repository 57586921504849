import { useQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { GET_LOGIN, GET_LOGOUT, ADD_STORE,DELETE_STORE,UPDATE_STORE,SAVE_FILTER, TEMPLATE_SAVE, GET_NEW_ACCESS_TOKEN, TEMPLATE_DELETE, UPLOAD_IMAGE, SEND_MESSAGE, FILTER_DELETE, SEND_TEMPLATE, SEND_MESSAGE_TO_ALL, LIST_MESSAGES, EDIT_TEMPLATE} from './mutations';
import { GET_STORELIST,GET_STORE,SEARCH_STORES,FILTER_STORES_QUERY,GET_FILTERS,GET_FILTER,LIST_TEMPLATES, ALL_MESSAGES} from './queries';
import t from '../apollo/client';
import { setData, setLoading, setError,setStoreData,setStoreError,setStoreLoading,setLoginData,setLoginDataLoading,setLoginError,setLogOutDataLoading,setLogOutError,setLogOutData,setAddStoreData,setAddStoreError,setAddStoreLoading,setDeleteStoreData,setDeleteStoreLoading,setDeleteStoreError,setUpdateStoreData,setUpdateStoreLoading,setUpdateStoreError,setSearchStoreData,setSearchStoreError,setFilterStoreData,setFilterStoreError,setFilterStoreLoading,setSearchStoreLoading,setFilterStoreListData,setFilterStoreListError,setFilterStoreListLoading,setGetFilterData,setGetFilterError,setGetFilterLoading,setSaveFilterData,setSaveFilterError,setSaveFilterLoading,setDataSearch,setDataError,setDataLoading,setSaveData,setSaveError,setSaveLoading
  ,setListData,setListLoading,setListError,
  setTokenData,
  setTokenLoading,
  setTokenError,
  setDeleteData,
  setDeleteLoading,
  setDeleteError,
  setImageLoading,
  setImageData,
  setImageError,
  setMessageData,
  setMessageLoading,
  setMessageError,
  setDeleteListTemplateSuccess,
  setFilterDeleteData,
  setFilterDeleteError,
  setFilterDeleteLoading,
  setSendTemplateLoading,
  setSendTemplateData,
  setSendTemplateError,
  setSendToAllLoading,
  setSendToAllData,
  setSendToAllError,
  setChatData,
  setChatLoading,
  setChatError,
  setEditData,
  setEditLoading,
  setEditError,
  setAllMsgData,
  setAllMsgLoading,
  setAllMsgError,
  updateMessageLists
} from '../redux/slices/marketingSlice';
import client from '../apollo/client';


export const GetStoreList = () => async (dispatch) => {
 
    dispatch(setLoading(true));
    try {
      //const response = await axios.get('http://159.89.173.195:5000/api/stores');
      const { data } = await client.query({ query: GET_STORELIST });
    
      dispatch(setData(data));
      dispatch(setLoading(false));
    } catch (error) { 
      dispatch(setError(error));
      dispatch(setLoading(false));
    }
  };

  
export const GetStoreSearchList = () => async (dispatch) => {
 
    dispatch(setDataSearch(true));
    try {
      const { data } = await client.query({ query: GET_STORELIST });
      console.log("im excuted inside actions",data)
      dispatch(setDataSearch(data));
      dispatch(setDataLoading(false));
    } catch (error) {
      dispatch(setDataError(error));
      dispatch(setDataLoading(false));
    }
  };

  


  export const GetStoreDetails = (storeId) => async (dispatch) => {
    dispatch(setStoreLoading(true));
    
    try {
      const { data } = await client.query({
        query: GET_STORE,
        variables: { storeId},
      });
      dispatch(setStoreData(data));
      dispatch(setStoreLoading(false));
    } catch (error) {
      dispatch(setStoreError(error));
      dispatch(setStoreLoading(false));
    }
  };
 
 
  // actions.js or your slice file
export const getLogin = (email, password, deviceId) => async (dispatch) => {
  dispatch(setLoginDataLoading(true));
  try {
      const { data } = await client.mutate({
          mutation: GET_LOGIN,
          variables: {
              email: email,
              password: password,
              deviceId: deviceId,
          },
      });
      dispatch(setLoginData(data));
      if(data && data.login){
        localStorage.setItem("authToken",data.login.accessToken)
        localStorage.setItem("refreshToken",data.login.refreshToken)
      }
      dispatch(setLoginDataLoading(false));
  } catch (error) {
      console.error("API Error:", error);
      let errorMessage = "An unknown error occurred";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          errorMessage = error.graphQLErrors[0].message;
          console.error("GraphQL Error:", errorMessage);
      }
      dispatch(setLoginError(errorMessage));
      dispatch(setLoginDataLoading(false));
  }
};


export const getLogOut = (refreshToken) => async (dispatch) => {
  dispatch(setLogOutDataLoading(true));
  try {
      const { data } = await client.mutate({
          mutation: GET_LOGOUT,
          variables: {
              refreshToken
          },
      });
      console.log(data)
      // Ensure mutation was successful by checking the returned data
      if (data && data.logout) {
          // Only clear localStorage on successful logout
          localStorage.removeItem("authToken");
          localStorage.removeItem("refreshToken");
          
          dispatch(setLogOutData(data));
      } else {
          throw new Error("Logout failed - no valid response");
      }

      dispatch(setLogOutDataLoading(false));
  } catch (error) {
      console.error("API Error:", error);
      let errorMessage = "An unknown error occurred";
      
      // Handle GraphQL-specific errors
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          errorMessage = error.graphQLErrors[0].message;
          console.error("GraphQL Error:", errorMessage);
          // Try refreshing the token in case of GraphQL errors
           dispatch(getToken(refreshToken));
          //dispatch(getLogOut(refreshToken))
        
      } else {
          // Other errors
          dispatch(setLogOutError(errorMessage));
      }

      dispatch(setLogOutDataLoading(false));
  }
};



  export const AddStore_mutation = (storeName,mobileNo,whatsappNo,altMobileNo,emailId,select6,select7,select8,select9) => async (dispatch) => {
    dispatch(setAddStoreLoading(true));
    try {
      const { data } = await client.mutate({
        mutation: ADD_STORE,
        variables: { storeName:storeName,
          mobileNo:mobileNo,
          whatsappNo:whatsappNo,
          altMobileNo:altMobileNo,
          emailId:emailId,
          businessType:select6,
            state:select7,
            district:select8,
            city:select9,
         },
         refetchQueries: [{ query: GET_STORELIST }],
      });
      dispatch(setAddStoreData(data));
      dispatch(setAddStoreLoading(false));
    } catch (error) {
      dispatch(setAddStoreError(error));
      dispatch(setAddStoreLoading(false));
    }
  };


  export const UpdateStore_mutation = (storeid,storeName,mobileNo,whatsappNo,altMobileNo,emailId,select6,select7,select8,select9) => async (dispatch) => {
    dispatch(setUpdateStoreLoading(true));
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_STORE,
        variables: {storeId:storeid, storeName:storeName,mobileNo:mobileNo,whatsappNo:whatsappNo,altMobileNo:altMobileNo,emailId:emailId,businessType:select6,
            state:select7,district:select8,city:select9,
         },
         refetchQueries: [{ query: GET_STORELIST }],
      });
      dispatch(setUpdateStoreData(data));
      dispatch(setUpdateStoreLoading(false));
    } catch (error) {
      dispatch(setUpdateStoreError(error));
      dispatch(setUpdateStoreLoading(false));
    }
  };

  export const DeleteStore_mutation=(storeId)=>async(dispatch)=>{
    dispatch(setDeleteStoreLoading(true));
    try{
      const {data}=await client.mutate({
        mutation:DELETE_STORE,
        variables:{storeId:storeId},
        refetchQueries:[{query:GET_STORELIST}
        ],
      });
      dispatch(setDeleteStoreData(data));
      dispatch(setDeleteStoreLoading(false));
    }catch(error){
      dispatch(setDeleteStoreError(error));
      dispatch(setDeleteStoreLoading(false))
    }
  }

  export const SearchStore=(state,district,city,businessType,status,storeName)=>async(dispatch)=>{
    dispatch(setSearchStoreLoading(true))
    try{
      const {data}=await client.mutate({
        mutation:SEARCH_STORES,
        variables:{state:state,district:district,city:city,businessType:businessType,status:status,storeName:storeName},
        refetchQueries:[{query:GET_STORELIST}],
      });
      dispatch(setSearchStoreData(data));
      dispatch((setSearchStoreLoading(false)));
    }catch(error){
      dispatch(setSearchStoreError(error));
      dispatch(setSearchStoreLoading(false))
    }
  }

  export const filterStoreDetails=(filterName,storeName,state,district,city,businessType)=>async(dispatch)=>{
    console.log("this is berlin jino",filterName,state,district,city,businessType)
     dispatch(setFilterStoreLoading(true))
    try{
      const { data } = await client.query({
        query: FILTER_STORES_QUERY,
        variables: {filterName:filterName,storeName:storeName,state:state,district:district,city:city,businessType:businessType},
      });
      dispatch(setFilterStoreData(data))
      dispatch(setFilterStoreLoading(false))
    }catch(error){
      dispatch(setFilterStoreError(error));
      dispatch(setFilterStoreLoading(false))
    }
  }

  export const filterStoreList=()=>async(dispatch)=>{
    dispatch(setFilterStoreListLoading(true))
    try{
      const {data}=await client.query({
        query:GET_FILTERS
      });
      dispatch(setFilterStoreListData(data))
      dispatch(setFilterStoreListLoading(false))
    }catch(error){
      dispatch(setFilterStoreListError(error))
      dispatch(setFilterStoreListLoading(false))
    }
  }

  export const filterStore=(filterName)=>async(dispatch)=>{
    
    dispatch(setGetFilterLoading(true))
    try{
      const {data}=await client.query({
        query:GET_FILTER,
        variables:{filterName:filterName}
      });
      dispatch(setGetFilterData(data))
      dispatch(setGetFilterLoading(false))
    }catch(error){
      dispatch(setGetFilterError(error))
      dispatch(setGetFilterLoading(false))
    }
  }

  export const filterSave=(filterName,storeName,state,district,city,businessType)=>async(dispatch)=>{
    console.log("dummy",filterName,state,district,city,businessType)
    dispatch(setSaveFilterLoading(true))
    try{
      const {data}=await client.mutate({
        mutation:SAVE_FILTER,
        variables:{filterName:filterName,storeName:storeName,state:state,district:district,city:city,businessType:businessType},
        refetchQueries:[{query:GET_FILTERS}],
      })
      dispatch(setSaveFilterData(data))
      dispatch(setSaveFilterLoading(false))
    }catch(error){
      dispatch(setSaveFilterError(error))
      dispatch(setSaveFilterLoading(false))
    }
  }
  export const getTemplateSave = (
    templateName,
    language,  
    headerType,
    headerContent,
    headerVariable,
    headerVariableType,
    mediaId,
    body,
    bodyName,
    bodyType,
    button,
    footer,
    uploadSessionString
  ) => async (dispatch) => {
    dispatch(setSaveLoading(true));

    // Prepare the header variables
    const headerVariables = headerVariable
      ? { name: headerVariable, type: headerVariableType }
      : { name: " ", type: " " };
  
    // Prepare body variables as a single object, not a string
    const bodyVariable = bodyName
      ? { name: bodyName, type: bodyType }
      : { name: " ", type: " " }; // Ensure it's an object even if empty
  
    try {
      const {data} = await client.mutate({
        mutation: TEMPLATE_SAVE,
        variables: {
          input: {
            templateName,
            language,
            header: {
              type: headerType==="file"?"image":headerType,
              content: headerContent,
              variables: headerVariables,
              mediaId:mediaId ,
              uploadSessionString:uploadSessionString
              // Send as an object
            },
            body,
            bodyVariables: bodyVariable, // Send as an object, not a string
            button,
            footer
          },
        },
        refetchQueries: [{ query: LIST_TEMPLATES }],
      });
  
      
      
        dispatch(setSaveData(data));
      
      dispatch(setSaveLoading(false));
    } catch (error) {
      console.error("API Error:", error);
      let errorMessage = "An unknown error occurred";
  
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
        console.error("GraphQL Error:", errorMessage);
      } else if (error.message) {
        errorMessage = error.message;
      }
  
      dispatch(setSaveError(errorMessage));
      dispatch(setSaveLoading(false));
    }
  };
  
 
export const getTemplateList=()=>async(dispatch)=>{
    
  dispatch(setListLoading(true))
  try{
    const {data}=await client.query({
      query:LIST_TEMPLATES,
      
    });
    dispatch(setListData(data))
    dispatch(setListLoading(false))
  }catch(error){
    dispatch(setListError(error))
    dispatch(setListLoading(false))
  }
}

export const getToken=(refreshToken)=>async(dispatch)=>{
    
  dispatch(setTokenLoading(true))
  try{
    const {data}=await client.mutate({
      mutation:GET_NEW_ACCESS_TOKEN,
      variables:{
        refreshToken:refreshToken
      }
    });
    localStorage.setItem("authToken",data.getNewAccessToken.accessToken)
    console.log("dataToken",data)
    dispatch(setTokenData(data)) 
    if(data){
      dispatch(getLogOut(refreshToken))
    }      
    dispatch(setTokenLoading(false))
    
  }catch(error){
    dispatch(setTokenError(error))
    dispatch(setTokenLoading(false))
  }
}  


export const getTemplateDelete = (id) => async (dispatch) => {
  dispatch(setDeleteLoading(true));
  try {
    const { data } = await client.mutate({
      mutation: TEMPLATE_DELETE,
      variables: {
        id: id,
      },
      refetchQueries: [{ query: LIST_TEMPLATES }],
    });
    
    dispatch(setDeleteData(data));  // Success flag set inside this action
    dispatch(setDeleteLoading(false));
  } catch (error) {
    dispatch(setDeleteError(error));
    dispatch(setDeleteLoading(false));
  }
};


export const getEditTemplate = (
  id,
  templateName,
  language,
  headerType,
  headerContent,
  headerVariable,
  headerVariableType,
  body,
  bodyName,
  bodyType,
  button,
  footer
) => async (dispatch) => {
  dispatch(setEditLoading(true));

  const headerVariables = headerVariable
    ? { name: headerVariable, type: headerVariableType }
    : { name: " ", type: " " };

  const bodyVariable = bodyName
    ? { name: bodyName, type: bodyType }
    : { name: " ", type: " " }

  try {
    const { data } = await client.mutate({
      mutation: EDIT_TEMPLATE,
      variables: {
        id,
        templateName,
        language,
        header: {
          type: headerType,
          content: headerContent,
          variables: headerVariables,
          //mediaId,  // Ensure mediaId is passed
        },
        body,
        bodyVariables: bodyVariable,  // Ensure it's an array of variables
        button,
        footer,
      },
      refetchQueries: [{ query: LIST_TEMPLATES }],
    });

    dispatch(setEditData(data));
    dispatch(setEditLoading(false));
  } catch (error) {
    dispatch(setEditError(error));
    dispatch(setEditLoading(false));
  }
};



export const getImageUpload = (file) => async (dispatch) => {
  dispatch(setImageLoading(true));

  try {
    const { data } = await client.mutate({
      mutation: UPLOAD_IMAGE,
      variables: { file },
    });

    dispatch(setImageData(data));
  } catch (error) {
    const serializableError = {
      message: error.message,
      code: error.networkError?.statusCode || 500, // Include relevant error info
    };
    dispatch(setImageError(serializableError)); // Dispatch serializable error
  } finally {
    dispatch(setImageLoading(false));
  }
};



export const getSendMessage = (phoneNumber,message,id) => async (dispatch) => {
  dispatch(setMessageLoading(true)); // Show loading state
  try {
    const { data } = await client.mutate({
      mutation: SEND_MESSAGE,
      variables: {
        input: {
          
          phoneNumber:phoneNumber,
          message: message ,
          storeIds:[id]// Send message as input to mutation
        },
      },
    });
    dispatch(setMessageData(data)); // Dispatch success action with response data
  } catch (error) {
    dispatch(setMessageError(error)); // Dispatch error action if mutation fails
  } finally {
    dispatch(setMessageLoading(false)); // Hide loading state in both success and error cases
  }
};


export const getFilterDelete = (id) => async (dispatch) => {
  dispatch(setFilterDeleteLoading(true)); // Show loading state
  try {
    const { data } = await client.mutate({
      mutation: FILTER_DELETE,
      variables: {
       
          id:id // Send message as input to mutation
        },
        refetchQueries:[{query:GET_FILTERS}]
    });
    dispatch(setFilterDeleteData(data)); // Dispatch success action with response data
  } catch (error) {
    dispatch(setFilterDeleteError(error)); // Dispatch error action if mutation fails
  } finally {
    dispatch(setFilterDeleteLoading(false)); // Hide loading state in both success and error cases
  }
};

export const getSendTemplate = (input) => async (dispatch) => {
  dispatch(setSendTemplateLoading(true)); // Show loading state
  try {
    const { data } = await client.mutate({
      mutation: SEND_TEMPLATE,
      variables: {
        input:input,
      },
    });
    dispatch(setSendTemplateData(data)); // Dispatch success action with response data
  } catch (error) {
    dispatch(setSendTemplateError(error)); // Dispatch error action if mutation fails
  } finally {
    dispatch(setSendTemplateLoading(false)); // Hide loading state in both success and error cases
  }
};


export const getSendMessageToAll = (phoneNumber,templateName) => async (dispatch) => {
  dispatch(setSendToAllLoading(true)); // Show loading state
  try {
    const { data } = await client.mutate({
      mutation: SEND_MESSAGE_TO_ALL,
      variables: {
        input: {
          
          phoneNumber:phoneNumber,
          templateName: templateName // Send message as input to mutation
        },
      },
    });
    dispatch(setSendToAllData(data)); // Dispatch success action with response data
  } catch (error) {
    dispatch(setSendToAllError(error)); // Dispatch error action if mutation fails
  } finally {
    dispatch(setSendToAllLoading(false)); // Hide loading state in both success and error cases
  }
};



export const getMessageList=(storeId)=>async(dispatch)=>{
    
  dispatch(setChatLoading(true))
  try{
    const {data}=await client.query({
      query:LIST_MESSAGES,
      variables: {
        storeId
      }
    });
    dispatch(setChatData(data))
    dispatch(setChatLoading(false))
  }catch(error){
    dispatch(setChatError(error))
    dispatch(setChatLoading(false))
  }
}


export const getAllMessageList=()=>async(dispatch)=>{
    
  dispatch(setAllMsgLoading(true))
  try{
    const {data}=await client.query({
      query:ALL_MESSAGES
    });
    dispatch(setAllMsgData(data))
    dispatch(setAllMsgLoading(false))
  }catch(error){
    dispatch(setAllMsgError(error))
    dispatch(setAllMsgLoading(false))
  }
}

export const updateMessageList = (updatedList) => async(dispatch)=> {
  dispatch(updateMessageLists(updatedList))
};
