import React, { useEffect, useState } from "react";
import "./Stores.css";
import {
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Divider,
  Checkbox,
} from "../MUIcomponents/index";
import {
  HeaderFontSize_32,
  contentFontSize_16,
  HeaderNavigationFontSize_20,
  contentFontsize_14,
  contentFontsize_30,
  contentFontSize_20,
} from "../Typography/index";
import CustomButton from "../Commoncomponents/button/index";
import { useFormik } from "formik";
import { validationSchema } from "./validation";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AddStore_mutation } from "../../graphql/actions";
import { setClearAddStore } from "../../redux/slices/marketingSlice";
import { GetStoreList, GetStoreSearchList } from "../../graphql/actions";
export default function AddStore({ open, handleClose, setOpen }) {
  const [select6, setSelect6] = useState([]);
  const [select7, setSelect7] = useState("");
  const [select8, setSelect8] = useState("");
  const [select9, setSelect9] = useState("");
  const [checked1, setChecked1] = React.useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const upLgmatch = useMediaQuery(theme.breakpoints.up("xl"));

  const { addStoreDataSuccess } = useSelector((state) => state.marketingSlice);

  const  addStoreError  = useSelector((state) => state.marketingSlice.addStoreError?.message);

  useEffect(() => {
    if (addStoreDataSuccess) {
      message.success({
        content: "Store Created Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
      setTimeout(() => {
        setOpen(false);
        dispatch(setClearAddStore());
        dispatch(GetStoreList());
        dispatch(GetStoreSearchList());
      }, 3500);
    } 
    
   
  }, [addStoreDataSuccess,addStoreError]);

  const handleChange6 = (event) => {
    const { value } = event.target;
    setSelect6(typeof value === "string" ? value?.split(",") : value);
  };

  const handleChange7 = (event) => {
    setSelect7(event.target.value);
  };
  const handleChange8 = (event) => {
    setSelect8(event.target.value);
  };
  const handleChange9 = (event) => {
    setSelect9(event.target.value);
  };
  const handleChangeCheckout1 = (event) => {
    const isChecked = event.target.checked;
    setChecked1(isChecked);
    if (isChecked) {
      formik.setFieldValue("whatsappNo", formik.values.mobileNo);
    } else {
      formik.setFieldValue("whatsappNo", "");
    }
    // if(formik.values.mobileNo !== formik.values.whatsappNo){
    //   message.error({
    //     content: "Given mobile number and whatsapp number is not same",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "10vh",
    //       marginLeft: "15vw",
    //     },
    //     duration: 3.5,
    //   });
    // }
  };
  const BussinessTypeCreateStore = [
    { id: 1, label: "Restaurants" },
    { id: 2, label: "Pet Store" },
    { id: 3, label: "Fish & Meat Store" },
    { id: 4, label: "Supermarket" },
    { id: 5, label: "Bakery" },
    { id: 6, label: "Grocery Store" },
    { id: 7, label: "Home Business" },
    { id: 8, label: "Electronics" },
    { id: 9, label: "Pharmacy" },
    { id: 10, label: "Crafts" },
    { id: 11, label: "Stationary" },
    { id: 12, label: "Beauty" },
  ];

  const stateCreateStore = [
    { id: 1, label: "Tamil Nadu" },
    { id: 2, label: "kerala" },
    { id: 3, label: "Telugana" },
  ];
  const districtCreateStore = [
    { id: 1, label: "Kanyakumari" },
    { id: 2, label: "Trunelveli" },
    { id: 3, label: "Chennai" },
  ];
  const cityCreateStore = [
    { id: 1, label: "Paloor" },
    { id: 2, label: "karungal" },
    { id: 3, label: "Nagarcoil" },
  ];
  const formik = useFormik({
    initialValues: {
      storeName: "",

      mobileNo: "",
      whatsappNo: "",
      altMobileNo: "",
      emailId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let {
        storeName,
        mobileNo,
        whatsappNo,
        altMobileNo,
        emailId,
      } = values;

      if (select6 === "") {
        message.error({
          content: "please choose the Business Type",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
      } else if (select7 === "") {
        message.error({
          content: "please choose the state",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
      } else if (select8 === "") {
        message.error({
          content: "please choose the district",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
      } else if (select9 === "") {
        message.error({
          content: "please choose the city",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
      }
      else if(addStoreError){
        message.error({
          
          content: "Store with this storeName, city, and mobileNo already exists.",
          className: "custom-success-message",
          style: {
            marginTop: "15vh",
            marginLeft: "15vw",
          },
          duration: 3,
          
        });
        setTimeout(() => {
          setOpen(false);
          dispatch(setClearAddStore());
          dispatch(GetStoreList());
          dispatch(GetStoreSearchList());
        }, 3500);
        console.log("no")
      }
       else {
        dispatch(
          AddStore_mutation(
            storeName,
            mobileNo,
            whatsappNo,
            altMobileNo,
            emailId,
            select6,
            select7,
            select8,
            select9
          )
        );
      }
    },
  });

  const handleResetForm = () => {
    formik.resetForm();
    setSelect6([]);
    setSelect7("");
    setSelect8("");
    setSelect9("");
    setChecked1(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="900"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <Typography
                style={{ fontFamily: "Heebo", fontWeight: "400" }}
                fontSize={contentFontsize_30}
              >
                Add New Store
              </Typography>
              <Divider />
              <div style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Heebo", fontWeight: "400" }}
                    fontSize={contentFontSize_20}
                  >
                    Store Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Enter Store name"
                    sx={{
                      width: "100%",

                      "& .MuiOutlinedInput-root": {
                        borderRadius: "13px",
                        "& fieldset": {
                          borderColor: "#0D75BA", // This will change the border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0D75BA", // This will change the border color when focused
                        },
                      },

                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                    }}
                    size="small"
                    InputProps={{
                      sx: {
                        fontSize: matches && "13px",

                        background: "#0D75BA",
                      },
                    }}
                    value={formik.values.storeName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.storeName &&
                      Boolean(formik.errors.storeName)
                    }
                    helperText={
                      formik.touched.storeName && formik.errors.storeName
                    }
                    name="storeName"
                  />
                  <Typography
                    style={{ fontFamily: "Heebo", fontWeight: "400" }}
                    fontSize={contentFontSize_20}
                  >
                    Choose Business Type
                  </Typography>

                  <div>
                    <Box sx={{ minWidth: 200 }}>
                      <FormControl fullWidth>
                        <Select
                          multiple
                          
                          sx={{
                            borderRadius: "10px",
                            height: "42px",
                            width: "100%",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#0D75BA",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#0D75BA",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#0D75BA",
                            },
                          }}
                          displayEmpty
                          value={select6}
                          onChange={handleChange6}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                    color: "#818181",
                                  }}
                                  fontSize={16}
                                >
                                  Select
                                </Typography>
                              );
                            }
                            return selected.join(", ");
                          }}
                        >
                          {BussinessTypeCreateStore.map((item) => (
                            <MenuItem key={item.id} value={item.label}>
                              <Checkbox
                                checked={select6.indexOf(item.label) > -1}
                              />
                              <Typography
                                style={{
                                  fontFamily: "Heebo",
                                  fontWeight: "400",
                                }}
                                fontSize={16}
                              >
                                {item.label}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>

                  <div
                    style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: smmatch && "100%",
                      }}
                    >
                      <Typography
                        style={{ fontFamily: "Heebo", fontWeight: "400" }}
                        fontSize={contentFontSize_20}
                      >
                        State
                      </Typography>
                      <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
                        <FormControl fullWidth>
                          <Select
                            sx={{
                              borderRadius: "10px",
                              height: matches ? "36px" : "42px",
                              width: "100%",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0D75BA",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#0D75BA",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0D75BA",
                              },
                            }}
                            displayEmpty
                            value={select7}
                            onChange={handleChange7}
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <Typography
                                    style={{
                                      fontFamily: "Heebo",
                                      fontWeight: "400",
                                      color: "#818181",
                                    }}
                                    fontSize={contentFontSize_16}
                                  >
                                    Select
                                  </Typography>
                                );
                              }
                              return (
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                  }}
                                  fontSize={contentFontSize_16}
                                >
                                  {selected}
                                </Typography>
                              );
                            }}
                          >
                            {stateCreateStore.map((item) => (
                              <MenuItem key={item.id} value={item.label}>
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                  }}
                                  fontSize={contentFontSize_16}
                                >
                                  {item.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: smmatch && "100%",
                      }}
                    >
                      <Typography
                        style={{ fontFamily: "Heebo", fontWeight: "400" }}
                        fontSize={contentFontSize_20}
                      >
                        District
                      </Typography>
                      <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
                        <FormControl fullWidth>
                          <Select
                            sx={{
                              borderRadius: "10px",
                              height: matches ? "36px" : "42px",
                              width: "100%",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0D75BA",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#0D75BA",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0D75BA",
                              },
                            }}
                            displayEmpty
                            value={select8}
                            onChange={handleChange8}
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <Typography
                                    style={{
                                      fontFamily: "Heebo",
                                      fontWeight: "400",
                                      color: "#818181",
                                    }}
                                    fontSize={contentFontSize_16}
                                  >
                                    Select
                                  </Typography>
                                );
                              }
                              return (
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                  }}
                                  fontSize={contentFontSize_16}
                                >
                                  {selected}
                                </Typography>
                              );
                            }}
                          >
                            {districtCreateStore.map((item) => (
                              <MenuItem key={item.id} value={item.label}>
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                  }}
                                  fontSize={contentFontSize_16}
                                >
                                  {item.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: smmatch && "100%",
                      }}
                    >
                      <Typography
                        style={{ fontFamily: "Heebo", fontWeight: "400" }}
                        fontSize={contentFontSize_20}
                      >
                        City
                      </Typography>
                      <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
                        <FormControl fullWidth>
                          <Select
                            sx={{
                              borderRadius: "10px",
                              height: matches ? "36px" : "42px",
                              width: "100%",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0D75BA",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#0D75BA",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0D75BA",
                              },
                            }}
                            displayEmpty
                            value={select9}
                            onChange={handleChange9}
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <Typography
                                    style={{
                                      fontFamily: "Heebo",
                                      fontWeight: "400",
                                      color: "#818181",
                                    }}
                                    fontSize={contentFontSize_16}
                                  >
                                    Select
                                  </Typography>
                                );
                              }
                              return (
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                  }}
                                  fontSize={contentFontSize_16}
                                >
                                  {selected}
                                </Typography>
                              );
                            }}
                          >
                            {cityCreateStore.map((item) => (
                              <MenuItem key={item.id} value={item.label}>
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                  }}
                                  fontSize={contentFontSize_16}
                                >
                                  {item.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  </div>

                  {/* end */}
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        flex: "1 1 0",
                      }}
                    >
                      <Typography
                        style={{ fontFamily: "Heebo", fontWeight: "400" }}
                        fontSize={contentFontSize_20}
                      >
                        Mobile Number
                      </Typography>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Mobile Number"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "& fieldset": {
                              borderColor: "#0D75BA",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0D75BA",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                          },
                        }}
                        size="small"
                        InputProps={{
                          // sx: {
                          //   fontSize: matches ? '13px' : 'inherit',
                          //   background: "#0D75BA",
                          // },
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ color: "black", fontSize: "16px" }}>
                                +91
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        value={formik.values.mobileNo}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.mobileNo &&
                          Boolean(formik.errors.mobileNo)
                        }
                        helperText={
                          formik.touched.mobileNo && formik.errors.mobileNo
                        }
                        name="mobileNo"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        flex: "1 1 0",
                      }}
                    >
                      <Typography
                        style={{ fontFamily: "Heebo", fontWeight: "400" }}
                        fontSize={contentFontSize_20}
                      >
                        Whats App Number
                      </Typography>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Whatsapp Number"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "& fieldset": {
                              borderColor: "#0D75BA",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0D75BA",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                          },
                        }}
                        size="small"
                        InputProps={{
                          // sx: {
                          //   fontSize: matches ? '13px' : 'inherit',
                          //   background: "#0D75BA",
                          // },
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ color: "black", fontSize: "16px" }}>
                                +91
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        value={
                          checked1
                            ? formik.values.mobileNo
                            : formik.values.whatsappNo
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.whatsappNo &&
                          Boolean(formik.errors.whatsappNo)
                        }
                        helperText={
                          formik.touched.whatsappNo && formik.errors.whatsappNo
                        }
                        name="whatsappNo"
                      />

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Heebo",
                            fontWeight: "400",
                            color: "#818181",
                          }}
                          fontSize={contentFontSize_16}
                        >
                          Same As Mobile
                        </Typography>
                        <Checkbox
                          style={{ color: "#8F9BBA", padding: "0px" }}
                          checked={checked1}
                          onChange={handleChangeCheckout1}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        flex: "1 1 0",
                      }}
                    >
                      <Typography
                        style={{ fontFamily: "Heebo", fontWeight: "400" }}
                        fontSize={contentFontSize_20}
                      >
                        Alternative Mobile Number{" "}
                      </Typography>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Alternative Mobile Number"
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "& fieldset": {
                              borderColor: "#0D75BA", // This will change the border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0D75BA", // This will change the border color when focused
                            },
                          },

                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                          },
                        }}
                        size="small"
                        InputProps={{
                          // sx: {
                          //   fontSize: matches ? '13px' : 'inherit',
                          //   background: "#0D75BA",
                          // },
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ color: "black", fontSize: "16px" }}>
                                +91
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        value={formik.values.altMobileNo}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.altMobileNo &&
                          Boolean(formik.errors.altMobileNo)
                        }
                        helperText={
                          formik.touched.altMobileNo &&
                          formik.errors.altMobileNo
                        }
                        name="altMobileNo"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        flex: "1 1 0",
                      }}
                    >
                      <Typography
                        style={{ fontFamily: "Heebo", fontWeight: "400" }}
                        fontSize={contentFontSize_20}
                      >
                        Email ID
                      </Typography>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Email ID"
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "& fieldset": {
                              borderColor: "#0D75BA", // This will change the border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0D75BA", // This will change the border color when focused
                            },
                          },

                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                          },
                        }}
                        size="small"
                        InputProps={{
                          sx: {
                            fontSize: matches && "13px",

                            background: "#0D75BA",
                          },
                        }}
                        value={formik.values.emailId}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.emailId &&
                          Boolean(formik.errors.emailId)
                        }
                        helperText={
                          formik.touched.emailId && formik.errors.emailId
                        }
                        name="emailId"
                      />
                    </div>
                  </div>

                  {/* <div style={{ display: "flex", gap: "20px", width: "100%", flexWrap: "wrap" }}>
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px", width: "100%" }}>
                    <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_20}>Alternative Mobile Number </Typography>
                    <TextField
                      variant="outlined"
                      placeholder='Enter Alternative Mobile Number'
                      sx={{
                        width: '100%',

                        '& .MuiOutlinedInput-root': {
                          borderRadius: "13px",
                          '& fieldset': {
                            borderColor: '#0D75BA', // This will change the border color
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#0D75BA', // This will change the border color when focused
                          },
                        },

                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',

                        },
                      }}
                      size='small'
                      InputProps={{
                        sx: {
                          fontSize: matches && '13px',

                          background: "#0D75BA"
                        },
                      }}
                      
                    value={formik.values.altMobileNo}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.altMobileNo &&
                        Boolean(formik.errors.altMobileNo)
                    }
                    helperText={
                        formik.touched.altMobileNo &&
                        formik.errors.altMobileNo
                    }
                    name="altMobileNo"
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px", width: "100%" }}>
                    <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_20}>Email ID</Typography>
                    <TextField
                      variant="outlined"
                      placeholder='Enter Email ID'
                      sx={{
                        width: '100%',

                        '& .MuiOutlinedInput-root': {
                          borderRadius: "13px",
                          '& fieldset': {
                            borderColor: '#0D75BA', // This will change the border color
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#0D75BA', // This will change the border color when focused
                          },
                        },

                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',

                        },
                      }}
                      size='small'
                      InputProps={{
                        sx: {
                          fontSize: matches && '13px',

                          background: "#0D75BA"
                        },
                      }}
                      
                        value={formik.values.emailId}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.emailId &&
                            Boolean(formik.errors.emailId)
                        }
                        helperText={
                            formik.touched.emailId &&
                            formik.errors.emailId
                        }
                        name="emailId"
                    />

                  </div>
                </div> */}
                </div>

                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <CustomButton
                    matchPadding={true}
                    padding={matches ? "4px 40px" : "8px 50px"}
                    background="#0D75BA"
                    borderRadius="10px"
                    onClick={handleResetForm}
                  >
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Heebo",
                        fontWeight: "500",
                      }}
                    >
                      Reset
                    </Typography>{" "}
                  </CustomButton>
                  <CustomButton
                    matchPadding={true}
                    padding={matches ? "4px 40px" : "8px 50px"}
                    background="#0D75BA"
                    borderRadius="10px"
                    type="submit"
                  >
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Heebo",
                        fontWeight: "500",
                      }}
                    >
                      Save
                    </Typography>{" "}
                  </CustomButton>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
