import React, { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Line from "../../Utils/Images/Line.svg";
import { contentFontSize_16, contentFontSize_16_xs_10 } from "../Typography";
import Rectangle from "../../Utils/Images/Rectangle.svg";
import Edit from "../../Utils/Images/Edit.svg";
import Delete from "../../Utils/Images/Delete.svg";
import TemplateForm from "./TemplateForm";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from "react-router-dom";
import CustomCard from "../Commoncomponents/card";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../Commoncomponents/button";
import { getEditTemplate, getTemplateDelete, getTemplateList } from "../../graphql/actions";
import { message } from "antd";
import { setDeleteTemplateSuccess, setEditTemplateSuccess } from "../../redux/slices/marketingSlice";
import CustomDialog from "../Commoncomponents/dialog";
import Plus from "../../Utils/Images/Plus.svg";
export default function Template() {
  const theme = useTheme();
  //const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const steps = ["Select master ", "Create an ad", "Create an ad1"];
  const [form, setForm] = useState(false);
  const nav = useNavigate();
  function handleCreate() {
    //setForm(true)
    localStorage.removeItem("formValues");
    nav("/template/templateForm");
  }

  const smMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMatch = useMediaQuery(theme.breakpoints.down("md"));
  const lgMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const isLessThan350 = useMediaQuery("(max-width:350px)");
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose() {
    setAnchorEl(null);
    setOpen(false)
    setDeletes(false);
  }
  useEffect(() => {
    dispatch(getTemplateList()); 
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const listData = useSelector((state) => state.marketingSlice?.listData);
  console.log("data", listData?.listTemplates);
  const deleteData = useSelector((state) => state.marketingSlice?.deleteData);
  const [deletes, setDeletes] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null); // For tracking the item to delete

  const handleDialog = (id) => {
    setSelectedItemId(id); // Set the ID of the item you want to delete
    setDeletes(true); // Open the dialog
  };
  // function handleClose() {
  //   setDeletes(false);
  // }
  function handleDelete(id) {
    dispatch(getTemplateDelete(id));

    setDeletes(false);
  }
  const deleteTemplateSuccess = useSelector(
    (state) => state.marketingSlice.deleteTemplateSuccess
  );
  console.log("success", deleteTemplateSuccess);

  useEffect(() => {
    if (deleteTemplateSuccess) {
      setDeletes(false);
      message.success({
        content: "Template Deleted Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });

      setTimeout(() => {
        // Reload the list or perform any other actions
        dispatch(setDeleteTemplateSuccess());
        dispatch(getTemplateList());
        // Reset the success flag after handling it
      }, 1500);
    }
  }, [deleteTemplateSuccess]);

  console.log("deleteData", deleteData);

  const [open, setOpen] = useState(false);
  const [templateDetails, setTemplateDetails] = useState("");
  const handleEdit = (id) => {
    setOpen(true);
    // Find the item in the listTemplates array with the matching id

    const itemDetails = listData?.listTemplates?.find((item) => item.id === id);
    setTemplateDetails(itemDetails);
    if (itemDetails) {
      // Proceed with the edit logic using the found item details
      console.log("Editing item:", itemDetails);
      // You can now use itemDetails to populate the form for editing or any other logic
    } else {
      // Handle the case where the item is not found
      console.log(`Item with id: ${id} not found`);
    }
  };
  const [header, setHeader] = useState("")
  const [bodyy, setBody] = useState("")
  const [buttons, setButton] = useState("")
  const [footers, setFooter] = useState("")
  const [templateNames,setTemplateName] = useState("")
  const [languages,setLanguage] = useState("")
  const [templateid,setTemplateId] = useState("")
  useEffect(() => {
    // Initialize the header with the existing value if it exists
    if (templateDetails) {
      setTemplateId(templateDetails.id)
      setHeader(templateDetails.header.content);
      setBody(templateDetails.body);
      setButton(templateDetails.button);
      setFooter(templateDetails.footer);
      setTemplateName(templateDetails.templateName)
      setLanguage(templateDetails.language === "en_US" ? "en" : templateDetails.language === "ta" ? "ta": templateDetails.language === "hi"?"hi":templateDetails.language === "ml"?"ml":templateDetails.language === "kn"?"kn" :templateDetails.language === "te" ? "te":  templateDetails.language);
    }
  }, [templateDetails]);

  function handleHeader(event) {
    const newValue = event.target.value;
    setHeader(newValue);
    console.log("header", newValue); // Log the updated value
  }
  function handleTemplate(event) {
    const newName = event.target.value;
    setTemplateName(newName);
    console.log("template", newName); // Log the updated value
  }
  function handleLanguage(event) {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    console.log("language", newLanguage); // Log the updated value
  }
  // const handleLanguage = (event) => {
  //   const value = event.target.value === 'en_US' ? 'en' : event.target.value;
  //   setLanguage(value);
  // };
  
  function handlebody(event) {
    const newBody = event.target.value;
    setBody(newBody);
    console.log("body", newBody);
  }

  function handleButton(event) {
    const newButton = event.target.value;
    setButton(newButton);
    console.log("button", newButton);
  }
 
  function handleFooter(event) {
    const newFooter = event.target.value;
    setFooter(newFooter);
    console.log("footer", newFooter);
  }
  const [selectedOption, setSelectedOption] = useState("");
  
  const [open5,setOpen5] = useState(false)
  function handleClickOpen(){
    setOpen5(true)
  }
  const [select1,setSelect1] = useState('')
  const languageOptions = [
    { value: "en" ,label: "English" },
    {  value: "hi",label: "Hindi" },
    {value: "ta", label: "Tamil" },
    { value: "ml", label: "Malayalam" },
    { value: "kn" , label: "Kannada"},
    {value: "te", label: "Telugu" }
  ];
  const [selectedPair, setSelectedPair] = useState("");

  const radioLabels = {
    pair1: "Interested / Not Interested"
    // ,     
    // pair2: "View Website / Call Now",
    // pair3: "Get Started / Learn More",
    // pair4: "Subscribe / No Thanks",
    // pair5: "Shop Now / View Details",
    // pair6: "Claim Offer / Skip Offer",
    // pair7: "Schedule Call /Message",
    // pair8: "Download App / Use Web Version",
    // pair9: "Explore Products / Request Info",
    // pair10: "Apply Now / Request More Info",
  };
  const [selectedValue, setSelectedValue] = useState("pair1");

const handleRadioChange = (event) => {
  setSelectedValue(event.target.value);
};

  const [selectedLabels, setSelectedLabels] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("");
  // const handleRadioChange = (event) => {
  //   const selectedValue = event.target.value;
  //   setSelectedLabels(radioLabels[selectedValue]); // Get the label based on the value

  //   setSelectedPair(selectedValue);
  //   console.log("Selected value:", selectedValue);
  //   console.log("Selected label:", selectedLabel); // Store the label in a variable
  // };
  function handleChange1(event){
    setSelect1(event.target.value)
  }
  const handleSelect = (option) => {
    setSelectedOption(option); // Save the selected option in the state
    setAnchorEl(null); // Close the dropdown
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  console.log(selectedOption)
  const [isBodyMenu, setIsBodyMenu] = useState(false);
  const handleIconClick = (event, isBody = false) => {
    setAnchorEl(event.currentTarget);
    setIsBodyMenu(isBody); // Track which menu (header or body) is open
  };
  const [headerOption,setHeaderOption] = useState("")
  const handleMenuItemClick = (option) => {
    setHeaderOption(option)
    setHeader((prevValue) => `${prevValue} ${option}`);
    handleMenuClose();
  };

  const [bodyOption,setBodyOption] = useState("")
  const handleMenuItemClickBody = (option) => {
    setBodyOption(option)
    setBody((prevValue) => `${prevValue} ${option}`);
    handleMenuClose();
  };
 

  function handleEditTemplate() {
    const id = templateid;  // Ensure templateid is defined
    const templateName = templateNames;  // Ensure templateNames is defined
    const language = languages; 
    const headerContent = header;
    const headerType = "String";
    const headerVariable = headerOption;  
    const headerVariableType = "String"; 
    const body = bodyy; 
    const bodyName = bodyOption;  
    const bodyType = "String";
    const button = buttons;  
    const footer = footers;
  
    dispatch(
      getEditTemplate(
        id,
        templateName,
        language,
        headerType,
        headerContent,
        headerVariable,
        headerVariableType,
        body,
        bodyName,
        bodyType,
        button,
        footer
      )
    );
  }
  const editTemplateSuccess = useSelector(
    (state) => state.marketingSlice.editTemplateSuccess
  );
  useEffect(() => {
    if (editTemplateSuccess) {
      //setDeletes(false);
      setOpen(false)
      message.success({
        content: "Template Edited Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });

      setTimeout(() => {
        // Reload the list or perform any other actions
        dispatch(setEditTemplateSuccess());
        dispatch(getTemplateList());
        // Reset the success flag after handling it
      }, 1500);
    }
  }, [editTemplateSuccess]);



  return (
    <>
      <div>
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "Inter",
            fontSize: "28px",
            marginBottom: "30px",
          }}
        >
          Template
        </Typography>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            //minHeight: "150vh",
            //height:"50%",
            border: "1px solid #ccc",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 25px 5px",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
            //overflowY: "auto", 
          }}
        >
          {/* Add pseudo-element for horizontal line */}
          <div
            style={{
              position: "absolute",
              content: '""',
              top: "150px",
              left: 0,
              right: 0,
              borderBottom: "1px solid #7C7B7B",
              zIndex: 1,
            }}
          />

          <div
            style={{
              width: "30%",
              backgroundColor: "white",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              position: "relative",
              zIndex: 0,
              
            }}
          >
            {/* Left content */}
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "Inter",
                fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              Available Template
            </Typography>

            {/* Add button */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
                sx={{
                  width: smMatch ? "60%" : mdMatch ? "130px" : "300px",
                  marginTop: isLessThan350 ? "30px" : "120px",
                  textAlign: "center",
                  textTransform: "capitalize",
                  height: "50px",
                  borderRadius: "10px",
                }}
              >
                Create New Template
              </Button>
            </div>
            
         <div
  style={{
    height: "600px", // Ensure it takes up full height
    overflowY: "scroll",  // Enable vertical scrolling
    display: "flex",
    flexDirection: "column",  // Stack content vertically
  }}
>
  {listData?.listTemplates?.map((item) => (
    <div
      key={item.id}
      style={{
        display: "flex",
        flexDirection: lgMatch ? "column" : "row",
        justifyContent: mdMatch ? "center" : "space-evenly",
        marginTop: "20px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "0px",
        }}
      >
        <>
          <CustomCard
            width="300px"
            height="100%" // Ensure card takes full height
            padding="16px 16px"
            border="1px solid #0D75BA"
            borderRadius="10px"
            boxShadow= "0px 0px 12px 7px #0000001A"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography
                  sx={{ fontWeight: "500", fontFamily: "Heebo" }}
                  fontSize={contentFontSize_16}
                >
                  {item?.templateName}
                </Typography>
              </div>
              <div
                style={{
                  marginTop: lgMatch ? "0px" : "0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Edit}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEdit(item.id)} // Pass the id onClick
                />
                <img
                  src={Delete}
                  style={{ cursor: "pointer", marginLeft: "20px" }}
                  onClick={() => handleDialog(item.id)} // Open the dialog with item ID
                />
              </div>
            </div>
          </CustomCard>
        </>
      </div>
    </div>
  ))}
</div>

</div>

          {/*  */}
          {deletes && selectedItemId && (
            // <CustomDialog
            //   open={deletes}
            //   onClose={handleClose}
            //   top="20px"
            //   right="20px"
            //   width="400px"
            //   height="600px"
            //   borderRadius="25px"
            //   BackdropProps={{
            //     style: {
            //       backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity or color here
            //     },
            //   }}
            // >
            //   <DialogContent>
            //     <Typography
            //       sx={{ color: "black", fontWeight: "400", fontSize: "18px" }}
            //     >
            //       Are you sure you want to delete this Template?
            //     </Typography>
            //   </DialogContent>
            //   <DialogActions>
            //     <div
            //       style={{
            //         display: "flex",
            //         justifyContent: "center",
            //         alignItems: "center",
            //         marginRight: "70px",
            //         gap: "60px",
            //       }}
            //     >
            //       <div>
            //         <CustomButton
            //           onClick={() => handleDelete(selectedItemId)} // Delete the selected item
            //           color="white"
            //           background="#0D75BA"
            //         >
            //           Yes
            //         </CustomButton>
            //       </div>
            //       <div>
            //         <CustomButton
            //           onClick={handleClose}
            //           color="white"
            //           background="#0D75BA"
            //         >
            //           No
            //         </CustomButton>
            //       </div>
            //     </div>
            //   </DialogActions>
            // </CustomDialog>
            <Dialog
          open={deletes}
        >
          <DialogContent>
            <div style={{ height: "100%", width: "100%", padding: matches ? "10px" : "15px" }}>
              <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                <Typography fontSize={contentFontSize_16_xs_10} className='chatFontHeebo400Color'>Are you sure you want to delete this Template?</Typography>

                <div style={{ display: "flex", gap: matches ? "10px" : "15px", textAlign: "center", justifyContent: "space-evenly" }}>
                  <Typography fontSize={contentFontSize_16_xs_10} onClick={handleClose} className='chatFontHeebo400Color' style={{ cursor: "pointer" }}>Cancel</Typography>
                  <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
                  <Typography fontSize={contentFontSize_16_xs_10} onClick={() => handleDelete(selectedItemId)}  className='chatFontHeebo400Red' style={{ cursor: "pointer" }}>Delete</Typography>
                </div>

              </div>

            </div>
          </DialogContent>
        </Dialog>
          )}
        </div>
        {open && (
        <CustomDialog
          open={open}
          top="20px"
          right="20px"
          width="90%"
          height="600px"
          onClose={handleClose}
          borderRadius="25px"
        >
          <DialogContent>
          <TextField
              margin="dense"
              label="Template Name"
              type="text"
              fullWidth
              variant="outlined"
              value={templateNames}
              onChange={handleTemplate}
            />
            <Select
  name="language"
  variant="outlined"
  sx={{
    borderRadius: "5px",
    height: matches ? "40px" : "55px",
    width: smMatch ? "100%" : "100%",
    marginTop:"12px",
    marginBottom:"12px",
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#0D75BA',

    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0D75BA',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0D75BA',
    },
  }}
 
  value={languages}
  onChange={handleLanguage}
>
  {languageOptions.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ))}
</Select>


            <TextField
              margin="dense"
              label="Message Header"
              type="text"
              fullWidth
              variant="outlined"
              value={header}
              onChange={handleHeader}
            />
           <div style={{ marginTop: "15px", marginBottom: "15px", display: "flex", justifyContent: "flex-end" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginTop: "5px",
          }}
        >
          <img
            src={Plus}
            alt="Plus"
            onClick={(e) => handleIconClick(e, false)} // Open header menu
            style={{ cursor: "pointer" }}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && !isBodyMenu}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuItemClick("Store name")}>Store name</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("Store owner name")}>Store owner name</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("Location")}>Location</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("Business type")}>Business type</MenuItem>
          </Menu>
          <span style={{ color: "#0D75BA", fontFamily: "Roboto", fontWeight: "600", fontSize: "14px" }}>
            Add variables
          </span>
        </Box>
      </div>
            <TextField
              margin="dense"
              label="Body"
              type="text"
              fullWidth
              variant="outlined"
              value={bodyy}
              onChange={handlebody}
            />
             <div style={{ marginTop: "15px", marginBottom: "15px", display: "flex", justifyContent: "flex-end" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginTop: "5px",
          }}
        >
          <img
            src={Plus}
            alt="Plus"
            onClick={(e) => handleIconClick(e, true)} // Open body menu
            style={{ cursor: "pointer" }}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && isBodyMenu}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuItemClickBody("Store name")}>Store name</MenuItem>
            <MenuItem onClick={() => handleMenuItemClickBody("Store owner name")}>Store owner name</MenuItem>
            <MenuItem onClick={() => handleMenuItemClickBody("Location")}>Location</MenuItem>
            <MenuItem onClick={() => handleMenuItemClickBody("Business type")}>Business type</MenuItem>
          </Menu>
          <span style={{ color: "#0D75BA", fontFamily: "Roboto", fontWeight: "600", fontSize: "14px" }}>
            Add variables
          </span>
        </Box>
      </div>

            {/* <TextField
              margin="dense"
              label="Button"
              type="text"
              fullWidth
              variant="outlined"
              value={button}
              onChange={handleButton}
            /> */}
            <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Select
  name="radioSelection"
  variant="outlined"
  value={buttons} // Ensure button is used as the value here
  onChange={handleButton} // Use handleButton for changing the selected value
  sx={{
    borderRadius: "5px",
    width: "100%",
    height: "50px",
    marginBottom:"12px",
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#0D75BA',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0D75BA',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0D75BA',
    },
  }}
>
  {[ 
    { value: "Interested / Not Interested", label: "Interested / Not Interested" },
    { value: "View Website / Call Now", label: "View Website / Call Now" },
    { value: "Get Started / Learn More", label: "Get Started / Learn More" },
    { value: "Subscribe / No Thanks", label: "Subscribe / No Thanks" },
    { value: "Shop Now / View Details", label: "Shop Now / View Details" },
    { value: "Claim Offer / Skip Offer", label: "Claim Offer / Skip Offer" },
    { value: "Schedule Call /Message", label: "Schedule Call /Message" },
    { value: "Download App / Use Web Version", label: "Download App / Use Web Version" },
    { value: "Explore Products / Request Info", label: "Explore Products / Request Info" },
    { value: "Apply Now / Request More Info", label: "Apply Now / Request More Info" }
  ].map((item) => (
    <MenuItem key={item.value} value={item.value}>
      <FormControlLabel
        control={
          <Radio
            checked={buttons === item.value} // Compare with `button` state
            onChange={() => handleButton({ target: { value: item.value } })} // Set value on change
            sx={{
              color: "#0D75BA",
              "&.Mui-checked": {
                color: "#0D75BA",
              },
            }}
          />
        }
        label={item.label}
      />
    </MenuItem>
  ))}
</Select>




                      </Box>
            <TextField
              margin="dense"
              label="Footer"
              type="text"
              fullWidth
              variant="outlined"
              value={footers}
              onChange={handleFooter}
            />
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                //flexDirection: isLessThan540 ? "column" : "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                gap: "30px",
                //marginRight: smmatch ? "30px" : "80px",
              }}
            >
              <CustomButton
                onClick={handleClose}
                width="170px"
                height="43px"
                borderRadius="15px"
                color="#0D75BA"
                background="white"
                variant="outlined"
                border="5px solid #0D75BA"
                borderColor="#0D75BA"
              >
                <Typography
                  sx={{
                    color: "#0D75BA",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    fontWeight: "500",
                  }}
                >
                  Cancel
                </Typography>
              </CustomButton>
              <CustomButton
                onClick={handleEditTemplate}
                width="170px"
                height="43px"
                borderRadius="15px"
                color="white"
                background="#0D75BA"
                variant="contained"
              >
                <Typography
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    fontWeight: "500",
                  }}
                >
                  Add
                </Typography>
              </CustomButton>
            </Box>
          </DialogActions>
        </CustomDialog>
      )}
      </div>
    </>
  );
}
