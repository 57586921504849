// import React from 'react'
// import CustomButton from '../Commoncomponents/button'
// import { Typography } from '@mui/material'

// function TemplateElements() {
//   return (
//     <>
// <div style={{marginTop:"39px",width:"100%",height:"100px",background:"#D9D9D9",display:"flex",justifyContent:"center",alignItems:"center", gap:"30px"}}>
//         <div style={{display:"flex",justifyContent:"space-evenly",flexDirection:"row",alignItems:"center",alignContent:"center",gap:"50px"}}>
//                <div>
//                 <CustomButton background="#0D75BA">
//                   <Typography sx={{textTransform:"capitalize"}}>
//                   Body
//                   </Typography>
//                 </CustomButton>
//                </div>
//                <div>
//                <CustomButton background="#0D75BA" width="100px">
//                <Typography sx={{textTransform:"capitalize"}}>
//                   Buttons
//                   </Typography>
//                 </CustomButton>
//                </div>
//                <div>
//                <CustomButton background="#0D75BA">
//                <Typography sx={{textTransform:"capitalize"}}>
//                   Footer
//                   </Typography>
//                 </CustomButton>
//                </div>
//         </div>
//     </div>
//         </>
//   )
// }

// export default TemplateElements

import React, { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  DialogTitle,
  DialogContent,
  tableBodyClasses,
  DialogActions,
  Grid,
  MenuItem,
  Select,
  Menu,
  Dialog,
  RadioGroup,
} from "@mui/material";
import Line from "../../Utils/Images/Line.svg";
import {
  contentFontSize_16,
  contentFontsize_30,
  contentFontsize_14,
  contentFontSize_16_xs_10,
} from "../Typography";
import Rectangle from "../../Utils/Images/Rectangle.svg";
import Edit from "../../Utils/Images/Edit.svg";
import Delete from "../../Utils/Images/Delete.svg";
//import TemplateForm from "./TemplateForm";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useLocation, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import Smiley from "../../Utils/Images/Smiley.svg";
import Bold from "../../Utils/Images/Bold.svg";
import Italics from "../../Utils/Images/Italics.svg";
import Plus from "../../Utils/Images/Plus.svg";
import Food from "../../Utils/Images/Food.svg";
import CustomButton from "../Commoncomponents/button/index";
import CustomDialog from "../Commoncomponents/dialog/index";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomCard from "../Commoncomponents/card";
import "./TemplateElements.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTemplateDelete,
  getTemplateList,
  getTemplateSave,
} from "../../graphql/actions";
import { setDeleteTemplateSuccess, setReturnFlag } from "../../redux/slices/marketingSlice";
import { message } from "antd";
import { array } from "yup";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
export default function TemplateElements() {
  const theme = useTheme();

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const steps = [
    "Create New Template",
    "Add Template Elements",
    "Preview Template",
  ];
  const [form, setForm] = useState(false);
  const nav = useNavigate();
  // function handleCreate(){
  //   // setForm(true)
  //    nav("/template/templateForm")
  // }
  useEffect(() => {
    dispatch(getTemplateList());
  }, []);
  const location = useLocation();
  const upload = location?.state?.upload;
  //const location = useLocation();
  const files = location?.state?.files;
  const isLessThan350 = useMediaQuery("(max-width:350px)");
  const isLessThan540 = useMediaQuery("(max-width:540px)");
  const smMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMatch = useMediaQuery(theme.breakpoints.down("md"));
  const lgMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const [text, setText] = useState("");
  const [savedContent,setSaveddContent] = useState(JSON.parse(localStorage.getItem("secondValues")))
  function handleText(event) {
    setMedia("");
    setText(event.target.value);
    console.log("text", text);
  }
  const [media, setMedia] = useState("");

  const [open, setOpen] = useState(false);
  function handleBody() {
    setOpen(true);
  }
  function handleClose() {
    //setActive("cancel");
    setOpen(false);
    setButton(false);
    setFooter(false);
    setDialogOpen(false);
  }
  const [button, setButton] = useState(false);
  function handleButtons() {
    setButton(true);
  }

  const [footer, setFooter] = useState(false);
  function handleFooter() {
    setFooter(true);
  }
  const storedArray = JSON.parse(localStorage.getItem("array"));
  console.log("storedArray", storedArray);
  const [tempChange, setTempChange] = useState("");
  const [change, setChange] = useState("");

  const [add, setAdd] = useState(false);
  const handleAdd = () => {
    setChange(formik.values.tempChange); // Commit the formik value to main state on Add button click
    setAdd(true);
    setOpen(false);
  };
  useEffect(() => {
    localStorage.removeItem("array");
  }, []);
  console.log("change", change);
  const [checkedLabels, setCheckedLabels] = useState([]);
  const [labels, setLabels] = useState("");
  const [selectedLabel, setSelectedLabel] = useState(null); // State to track the selected label

  const handleCheckBox = (event) => {
    const label = event.target.labels[0].textContent;
    const { checked } = event.target;
    if (checked) {
      setSelectedLabel(label); // Update the state with the new selected label
    }
  };
  const [tempSelection, setTempSelection] = useState(savedContent ? savedContent[4].pair : ""); 
  const [apply, setApply] = useState(false);
  const handleApply = () => {
    setApply(true)
    formik.setFieldValue('radioSelection', tempSelection); // Apply changes to Formik state
    setButton(false); // Close the dialog
    console.log("tempSelection",tempSelection)
  };
  const [footerText, setFooterText] = useState("Marketing Team Nearshopz");

  const [one, setOne] = useState(false);
  function handleFooterOne() {
    setOne(true);
    setFooter(false);
  }
  const [dialogOpen, setDialogOpen] = useState(false);
  function handleModalOpen() {
    setDialogOpen(true);
  }

  // const [open, setOpen] = useState(false);
  // const [change, setChange] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    // Use formik.setFieldValue to update the Formik field
    setSelectedOption(option)
    formik.setFieldValue("tempChange", `${formik.values.tempChange || ''} ${option}`);
    handleMenuClose();
};
  // const handleTextFieldChange = (event) => {
  //   setTempChange(event.target.value); // Update tempChange instead of change
  // };

  //const [text, setText] = useState('');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const toggleBold = () => {
    setIsBold(!isBold);
  };

  const toggleItalic = () => {
    setIsItalic(!isItalic);
  };

  const [selectedPairs, setSelectedPairs] = useState(savedContent ? savedContent[4].value : "");

  const radioLabels = {
    pair1: "Interested / Not Interested"
    // ,
    // pair2: "View Website / Call Now",
    // pair3: "Get Started / Learn More",
    // pair4: "Subscribe / No Thanks",
    // pair5: "Shop Now / View Details",
    // pair6: "Claim Offer / Skip Offer",
    // pair7: "Schedule Call /Message",
    // pair8: "Download App / Use Web Version",
    // pair9: "Explore Products / Request Info",
    // pair10: "Apply Now / Request More Info",
  };
  const [selectedLabels, setSelectedLabels] = useState("");
  // const handleRadioChange = (event) => {
  //   const selectedValue = event.target.value;
  //   setSelectedLabels(radioLabels[selectedValue]); // Get the label based on the value

  //   setSelectedPair(selectedValue);
  //   console.log("Selected value:", selectedValue);
  //   console.log("Selected label:", selectedLabel); // Store the label in a variable
  // };
 
  // const [values, setValues] = useState({ radioSelection: '' });
  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   setValues({ ...values, radioSelection: value });

  //   // Update the label based on the selected value
  //   if (value === 'pair1') {
  //     setSelectedLabels('Interested / Not Interested');
  //   }
  // };
  // const applyFormatting = (tag) => {
  //   const textarea = document.getElementById('textField');
  //   const { selectionStart, selectionEnd, value } = textarea;
  //   const selectedText = value.substring(selectionStart, selectionEnd);

  //   // Wrap selected text with the chosen tag
  //   const newText = `${value.substring(0, selectionStart)}<${tag}>${selectedText}</${tag}>${value.substring(selectionEnd)}`;

  //   setChange(newText);
  // };
  const listData = useSelector((state) => state.marketingSlice?.listData);
  console.log("data", listData?.listTemplates);
  const [deletes, setDeletes] = useState(false);
  const deleteData = useSelector((state) => state.marketingSlice?.deleteData);
  // const [deletes, setDeletes] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null); // For tracking the item to delete

  const handleDialog = (id) => {
    setSelectedItemId(id); // Set the ID of the item you want to delete
    setDeletes(true); // Open the dialog
  };
  // function handleClose() {
  //   setDeletes(false);
  // }
  function handleDeletes(id) {
    dispatch(getTemplateDelete(id));

    setDeletes(false);
  }
  const deleteTemplateSuccess = useSelector(
    (state) => state.marketingSlice.deleteTemplateSuccess
  );
  console.log("success", deleteTemplateSuccess);

  useEffect(() => {
    if (deleteTemplateSuccess) {
      setDeletes(false);
      message.success({
        content: "Template Deleted Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });

      setTimeout(() => {
        // Reload the list or perform any other actions
        dispatch(setDeleteTemplateSuccess());
        dispatch(getTemplateList());
        // Reset the success flag after handling it
      }, 1500);
    }
  }, [deleteTemplateSuccess]);

  console.log("deleteData", deleteData);
  const isImage = /\.(jpg|jpeg|)$/i.test(upload?.fileUrl);
  console.log("isImage",isImage)
const isVideo = /\.(mp4)$/i.test(upload?.fileUrl);
  const leftSideContent = (
    <div
      style={{    
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        position: "relative",
        zIndex: 0,
        padding: "20px",
        //minHeight: "150vh",
        height: "100%",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontFamily: "Inter",
          fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Available Template
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: smMatch ? "60%" : mdMatch ? "130px" : "300px",
            marginTop: isLessThan350 ? "30px" : "120px",
            textAlign: "center",
            textTransform: "capitalize",
            height: "50px",
            borderRadius: "10px",
          }}
        >
          Create New Template
        </Button>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: mdMatch ? "column" : "row",
          justifyContent: mdMatch ? "center" : "space-evenly",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CustomCard width="30px" height="40px" />
          <Typography style={{ textAlign: "center", marginTop: "10px" }}>
            Welcome
          </Typography>
        </div>
        <div
          style={{
            marginTop: mdMatch ? "20px" : "0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Edit} style={{ cursor: "pointer" }} />
          <img src={Delete} style={{ cursor: "pointer", marginLeft: "20px" }} />
        </div>
      </div> */}
      {listData?.listTemplates?.map((item) => (
        // <div
        //   key={item.id}
        //   style={{
        //     display: "flex",
        //     flexDirection: lgMatch ? "column" : "row",
        //     justifyContent: mdMatch ? "center" : "space-evenly",
        //     marginTop: "20px",
        //     alignItems: "center",
        //   }}
        // >
        //   <div
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       alignItems: "center",
        //       marginLeft: "40px",
        //     }}
        //   >
        //     <>
        //       <CustomCard width="170px" height="100%" padding="10px 30px 80px 10px">
        //         <div>
        //           <Typography>{item?.header?.content}</Typography>
        //         </div>
        //         <div>
        //           <Typography>{item?.body}</Typography>
        //         </div>
        //         <div style={{ marginTop: "15px" }}>
        //           <CustomButton background="#0D75BA">
        //             <Typography style={{ textTransform: "capitalize" }}>
        //               {item.button}
        //             </Typography>
        //           </CustomButton>
        //         </div>
        //         <div style={{ marginTop: "15px" }}>
        //           <Typography>{item.footer}</Typography>
        //         </div>
        //       </CustomCard>

        //       <Typography style={{ textAlign: "center", marginTop: "10px" }}>
        //         {item.templateName}
        //       </Typography>
        //     </>
        //   </div>
        //   <div
        //     style={{
        //       marginTop: lgMatch ? "20px" : "0",
        //       display: "flex",
        //       alignItems: "center",
        //     }}
        //   >
        //     <img
        //       src={Edit}
        //       style={{ cursor: "pointer" }}
        //       onClick={() => handleEdit(item.id)} // Pass the id onClick
        //     />
        //     <img
        //       src={Delete}
        //       style={{ cursor: "pointer", marginLeft: "20px" }}
        //       onClick={() => handleDialog(item.id)} // Open the dialog with item ID
        //     />
        //   </div>
        // </div>
        <div
          key={item.id}
          style={{
            display: "flex",
            flexDirection: lgMatch ? "column" : "row",
            justifyContent: mdMatch ? "center" : "space-evenly",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "0px",
            }}
          >
            <>
              <CustomCard
                width="300px"
                height="100%"
                padding="16px 16px"
                border="1px solid #0D75BA"
                borderRadius="10px"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography
                      sx={{ fontWeight: "500", fontFamily: "Heebo" }}
                      fontSize={contentFontSize_16}
                    >
                      {item?.templateName}
                    </Typography>
                  </div>
                  <div
                    style={{
                      marginTop: lgMatch ? "0px" : "0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Edit}
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleEdit(item.id)} // Pass the id onClick
                    />
                    <img
                      src={Delete}
                      style={{ cursor: "pointer", marginLeft: "20px" }}
                      onClick={() => handleDialog(item.id)} // Open the dialog with item ID
                    />
                  </div>
                </div>
              </CustomCard>
            </>
          </div>
        </div>
      ))}

      {deletes && selectedItemId && (
        <Dialog open={deletes}>
          <DialogContent>
            <div
              style={{
                height: "100%",
                width: "100%",
                padding: mdMatch ? "10px" : "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <Typography
                  fontSize={contentFontSize_16_xs_10}
                  className="chatFontHeebo400Color"
                >
                  Are you sure you want to delete this Template?
                </Typography>

                <div
                  style={{
                    display: "flex",
                    gap: mdMatch ? "10px" : "15px",
                    textAlign: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography
                    fontSize={contentFontSize_16_xs_10}
                    onClick={handleClose}
                    className="chatFontHeebo400Color"
                    style={{ cursor: "pointer" }}
                  >
                    Cancel
                  </Typography>
                  <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
                  <Typography
                    fontSize={contentFontSize_16_xs_10}
                    onClick={() => handleDelete(selectedItemId)}
                    className="chatFontHeebo400Red"
                    style={{ cursor: "pointer" }}
                  >
                    Delete
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );

  const dispatch = useDispatch();
  const value = location.state?.value;
  const option = location.state?.option;

  const previousSave = location?.state?.previousSave;
  const returnFlag = location?.state?.returnFlag;
  const returnNextFlag = location?.state?.returnNextFlag;
 console.log("add",add)
  console.log("returnFlag", returnFlag);
  const previousNext = location?.state?.previousNext;
  console.log("previousNext", previousNext);
  console.log("option", option);
  console.log("hehe", value);
  const content = [
    { body: change, button: selectedLabels, footer: footerText },
  ];
  //const [array,setArray] = useState(content)
  //console.log("arrayy",array)
  function handleNext() {
    // Convert the array to a string before storing it in localStorage

    const templateName = value?.templateName;
    const language = value?.select1;
    const headerType = value?.messageText ? "text" : "file";
    const headerContent = value?.messageText ? value?.messageText : " ";
    const headerVariable = option;
    const headerVariableType = headerVariable ? "String" : "String";
    const body = change;
    const bodyName = selectedOption;
    const bodyType = selectedOption ? "String" : "";
    const button = selectedLabels;
    const footer = footerText;
    
    localStorage.setItem("array", JSON.stringify(content));
    nav("/template/templateSave", { state: { dataArray: dataArray } });
  }

  const dataArray = [
    {
      key: "templateName",
      value: value?.templateName,
    },
    {
      key: "select1",
      value: value?.select1,
    },
    {
      key: "header",
      type: value?.messageText ? "text" : "file",
      content: value?.messageText ? value?.messageText : " ",
      variable: option,
      variableType: "String",
    },
    {
      key: "body",
      content: change,
      name: selectedOption,
      type: "String",
    },
    {
      key: "button",
      value: selectedLabels,
    },
    {
      key: "footer",
      value: footerText,
    },
    {
      key: "media",
      value: upload?.h,
      id: upload?.id,
    },
  ];

  //   const loadSavedValues = () => {
  //     const savedValues = localStorage.getItem('array');
  //     return savedValues && !previousSave ? JSON.parse(savedValues) : null;
  //   };

  //   //const [array, setArray] = useState(loadSavedValues);
  //   //console.log("loadSavedValues",loadSavedValues)
  //   useEffect(() => {

  //     setArray(loadSavedValues())

  //   }, []);

  // useEffect(()=>{
  //   setArray("")
  //  //localStorage.removeItem('formValues')
  // },[])
  // useEffect(() => {
  //   const savedValues = JSON.parse(localStorage.getItem("secondValues"));
  //   if (savedValues) {
  //     formik.setValues({
  //       tempChange: setChange(savedValues[3].content || ''),
  //       radioSelection: savedValues[4].value || '',
  //     });
  //   }
  // }, []);


  const [previous, setPrevious] = useState(false);
  console.log("dataArray", dataArray);
 
  function handlePrevious() { 
    setPrevious(true);
    
    // if (savedValues) {
    //   formik.setValues({
    //     tempChange: setChange(savedValues[3]?.content || ''),
    //     radioSelection: savedValues[4].value || '',
    //   });
    // }
    nav("/template/templateForm", {
      state: { previous: previous },
    });
  }
  // {!previousSave && (
  //   setChange(localStorage.getItem('array')[0].body)

  // )}
  const mediaSrc = localStorage.getItem("media");
  console.log("Media Source:", mediaSrc);

  const handleEdit = () => {
    //formik.setFieldValue('tempChange', change); // Set the current value in the formik field when editing
    setOpen(true);
  };
  const handleEditButton = () => {
    setTempSelection(formik.values.radioSelection); // Set tempSelection to current selection
    setButton(true); // Open the dialog
  };
  function handleEditFooter() {
    setFooter(true);
  }

  function handleDelete() {
    setAdd(false);
    // setApply(false)
    // setOne(false)
  }
  function handleDelete1() {
    //setAdd(false)
    setApply(false);
    // setOne(false)
  }
  function handleDelete2() {
    //setAdd(false)
    // setApply(false)
    setOne(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const listData = useSelector((state) => state.marketingSlice?.listData);
  // console.log("data", listData?.listTemplates);

  // useEffect(()=>{
  //    setArray("")
  // },[])

  // useEffect(() => {
  //   if (previousSave && previousSave[0]?.body) {
  //     setChange(previousSave[0].body);
  //   } else if (previousNext && previousNext[0]?.body) {
  //     setChange(previousNext[0].body);
  //   }
  // }, [previousSave, previousNext]);

  const validationSchema = Yup.object({
    tempChange: Yup.string(),
    radioSelection: Yup.string(),
  });
 console.log("tempSelection",tempSelection)
 //console.log("valueSelection",formik.values.radioSelection)
  const textFieldValue = useSelector((state) => state.marketingSlice.textFieldValue);
  console.log("textFieldValue",textFieldValue)
  const formik = useFormik({     
    initialValues: {
      tempChange:returnNextFlag && savedContent ? savedContent[3].content : returnFlag && savedContent ? savedContent[3].content : null,
      radioSelection: returnNextFlag && savedContent ? savedContent[4].value : returnFlag && savedContent ? savedContent[4].value : null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const selectedPair = radioLabels[values.radioSelection] ?  radioLabels[values.radioSelection] : selectedPairs;
      
      console.log("selectedPair",selectedPair)
      console.log("values", values.tempChange);
      const dataArray = [
        {
          key: "templateName",
          value: value?.templateName,
        },
        {
          key: "select1",
          value: value?.select1,
        },
        {
          key: "header",
          type: value?.messageText ? "text" : "file",
          content: value?.messageText ? value?.messageText : " ",
          variable: option,
          variableType: "String",
        },
        {
          key: "body",
          content: values.tempChange,
          name: selectedOption,
          type: "String",
          NeedBold:isBold,
          needItalic:isItalic
        },
        {
          key: "button",
          value: selectedPair,
          pair:tempSelection 
        },
        {
          key: "footer",
          value: footerText,
        },
        {
          key: "media",
          value: upload?.h,
          id: upload?.id,
        },
      ];
      dispatch(setReturnFlag(true));
      setTempChange(values.tempChange);
      localStorage.setItem("secondValues", JSON.stringify(dataArray));
      const savedValues = JSON.parse(localStorage.getItem("secondValues")) || {
        tempChange: "",
        radioSelection: "",
      };
      //setSaveddContent(JSON.parse(localStorage.getItem("secondValues")));
      
      nav("/template/templateSave", { state: { dataArray: dataArray, savedValues: savedValues } });
    },
  });
  // React.useEffect(() => {
  //   // Get saved form data from local storage
  //   const savedFormData = localStorage.getItem('secondValues');
  
  //   if (savedFormData) {
  //     // If there is saved form data, update the form's initial values
  //     formik.setValues(JSON.stringify(savedFormData));
  //   }
  // }, []);
  
  return (
    <>
      <div>
        <Typography
          sx={{ fontWeight: "600", fontFamily: "Inter", fontSize: "28px" }}
        >
          Template
        </Typography>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            //minHeight: "100vh",
            border: "1px solid #ccc",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 25px 5px",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "125px",
              left: 0,
              right: 0,
              borderBottom: "1px solid #7C7B7B",
              zIndex: 1,
              flexGrow: 0,
              flexShrink: 0,
              maxHeight: "60px", // Set a maximum height
            }}
          />

          {isLessThan350 ? (
            <>
              <CustomDialog
                open={dialogOpen}
                onClose={handleClose}
                top="20px"
                right="20px"
              >
                <DialogContent>{leftSideContent}</DialogContent>
              </CustomDialog>
            </>
          ) : (
            <div style={{ width: "30%" }}>{leftSideContent}</div>
          )}

          <div style={{ flex: 1, padding: "0px" }}>
           
          <form onSubmit={formik.handleSubmit}>
            <div style={{ padding: "20px" }}>
              <Box sx={{ width: "100%", marginBottom: "0px" }}>
                <Stepper activeStep={1} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
            <div style={{ padding: "0px 0px 0px 0px" }}>
              {isLessThan350 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalOpen}
                  >
                    Show Templates
                  </Button>
                </div>
              )}
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isLessThan540 ? "column" : "row", // Stack buttons vertically on small screens
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40%", // Adjust as needed
                    backgroundColor: "#D9D9D9",
                    padding: "30px",
                    marginTop: "25px",
                    gap: "10px", // Small gap between buttons
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "10px", width: "100px" }}
                    onClick={handleBody}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      Body
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "10px", width: "100px" }}
                    onClick={handleButtons}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      Buttons
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "10px", width: "100px" }}
                    onClick={handleFooter}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      Footer
                    </Typography>
                  </Button>
                </Box>
                {/* <Formik
                  initialValues={{
                    tempChange: "",
                    radioSelection: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    console.log(values);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit}> */}
                      {open && (
                        <CustomDialog
                          open={open}
                          top="20px"
                          right="20px"
                          width="90%"
                          height="600px"
                          onClose={handleClose}
                          borderRadius="25px"
                        >
                          <DialogContent>
                            <Typography
                              fontSize="30px"
                              sx={{ fontFamily: "Roboto", color: "#0D75BA" }}
                            >
                              Body
                            </Typography>
                            <Typography
                              sx={{
                                color: "black",
                                fontFamily: "Roboto",
                                fontWeight: "500",
                              }}
                            >
                              Enter Your Text
                            </Typography>
                            <Box sx={{ marginTop: "20px" }}>
                              {/* <TextField
                          fullWidth
                          multiline
                          rows="4"
                          value={tempChange}
                          onChange={handleTextFieldChange}
                          InputProps={{
                            style: {
                              fontWeight: isBold ? "700" : "normal",

                              fontStyle: isItalic ? "italic" : "normal",
                            },
                          }}
                        /> */}
                              <TextField
      fullWidth
      multiline
      rows="4"
      name="tempChange"
      value={formik.values.tempChange}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur} // Track touched fields
      InputProps={{
        style: {
          fontWeight: isBold ? "700" : "normal",

                        fontStyle: isItalic ? "italic" : "normal",// Add isItalic logic here
        },
      }}
      error={formik.touched.tempChange && Boolean(formik.errors.tempChange)}
      helperText={formik.touched.tempChange && formik.errors.tempChange}
    />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                {/* Plus Icon with Dropdown */}
                                <div>
                                  <img
                                    src={Smiley}
                                    style={{ marginTop: "10px" }}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={Bold}
                                    onClick={toggleBold}
                                    style={{ marginTop: "10px" }}
                                  />
                                </div>

                                <div>
                                  <img
                                    src={Italics}
                                    onClick={toggleItalic}
                                    style={{ marginTop: "10px" }}
                                  />
                                </div>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <img
                                    src={Plus}
                                    alt="Plus"
                                    onClick={handleIconClick}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        handleMenuItemClick("Store name")
                                      }
                                    >
                                      Store name
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleMenuItemClick("Store owner name")
                                      }
                                    >
                                      Store owner name
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleMenuItemClick("Location")
                                      }
                                    >
                                      Location
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleMenuItemClick("Business type")
                                      }
                                    >
                                      Business type
                                    </MenuItem>
                                  </Menu>
                                  <span
                                    style={{
                                      color: "#0D75BA",
                                      fontFamily: "Roboto",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Add variables
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                gap: "30px",
                                marginRight: smmatch ? "30px" : "80px",
                              }}
                            >
                              <CustomButton
                                onClick={handleClose}
                                width="170px"
                                height="43px"
                                borderRadius="15px"
                                color="#0D75BA"
                                background="white"
                                variant="outlined"
                                border="5px solid #0D75BA"
                                borderColor="#0D75BA"
                              >
                                <Typography
                                  sx={{
                                    color: "#0D75BA",
                                    textTransform: "capitalize",
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                  }}
                                >
                                  Cancel
                                </Typography>
                              </CustomButton>
                              <CustomButton
                                onClick={
                                  handleAdd
                                  //setChange(values.tempChange);
                                }
                                width="170px"
                                height="43px"
                                borderRadius="15px"
                                color="white"
                                background="#0D75BA"
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    color: "white",
                                    textTransform: "capitalize",
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                  }}
                                >
                                  Add
                                </Typography>
                              </CustomButton>
                            </Box>
                          </DialogActions>
                        </CustomDialog>
                      )}
                      {button && (
                        <CustomDialog
                          open={button}
                          top="20px"
                          right="20px"
                          width="90%"
                          height="600px"
                          onClose={handleClose}
                          borderRadius="25px"
                        >
                          <DialogContent>
                            <Typography
                              fontSize="30px"
                              sx={{
                                fontFamily: "Roboto",
                                color: "#0D75BA",
                                fontWeight: "500",
                              }}
                            >
                              Add Buttons
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                              }}
                            >
                              
                              <RadioGroup
                name="radioSelection"
                value={tempSelection} // Use temporary state here
                onChange={(e) => setTempSelection(e.target.value)} // Update temporary state on change
                sx={{ color: '#0D75BA' }}
              >
                                
                                
                                {Object.keys(radioLabels).map((pair, index) => (
                <FormControlLabel
                  key={index}
                  value={pair}
                  control={
                    <Radio
                      sx={{
                        color: "#0D75BA",
                        "&.Mui-checked": {
                          color: "#0D75BA",
                        },
                      }}
                    />
                  }
                  label={radioLabels[pair]}
                />
              ))}
                              </RadioGroup>
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: isLessThan540 ? "column" : "row",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                gap: "30px",
                                marginRight: smmatch ? "30px" : "80px",
                              }}
                            >
                              <CustomButton
                                onClick={handleClose}
                                width="170px"
                                height="43px"
                                borderRadius="15px"
                                color="#0D75BA"
                                background="white"
                                variant="outlined"
                                border="5px solid #0D75BA"
                                borderColor="#0D75BA"
                              >
                                <Typography
                                  sx={{
                                    color: "#0D75BA",
                                    textTransform: "capitalize",
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                  }}
                                >
                                  Cancel
                                </Typography>
                              </CustomButton>
                              <CustomButton
                                onClick={handleApply}
                                width="170px"
                                height="43px"
                                borderRadius="15px"
                                color="white"
                                background="#0D75BA"
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    color: "white",
                                    textTransform: "capitalize",
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                  }}
                                >
                                  Apply
                                </Typography>
                              </CustomButton>
                            </Box>
                          </DialogActions>
                        </CustomDialog>
                      )}
                    
                    {footer && (
                  <CustomDialog
                    open={footer}
                    top="20px"
                    right="20px"
                    width="90%"
                    height="600px"
                    onClose={handleClose}
                    borderRadius="25px"
                  >
                    <DialogContent>
                      <Typography
                        fontSize={contentFontsize_30}
                        sx={{
                          fontFamily: "Roboto",
                          color: "#0D75BA",
                          fontWeight: "500",
                        }}
                      >
                        Footer
                      </Typography>
                      {/* <br/> */}
                      <Typography
                        sx={{
                          color: "black",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                        }}
                      >
                        Enter Footer Text
                      </Typography>
                      <div style={{ marginTop: "20px" }}>
                        <TextField
                          fullWidth
                          multiline={true}
                          rows="1"
                          value={footerText}
                        ></TextField>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: isLessThan540 ? "column" : "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "30px",
                          marginRight: smmatch ? "30px" : "80px",
                        }}
                      >
                        <CustomButton
                          onClick={handleClose}
                          width="170px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontFamily: "Inter",
                              fontWeight: "500",
                            }}
                          >
                            Cancel
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          onClick={handleFooterOne}
                          width="170px"
                          height="43px"
                          // variant={active === "change" ? "contained" : "outlined"}
                          // background={active === "change" ? "#659B1B" : "transparent"}
                          // color={active === "change" ? "white" : "#659B1B"}
                          borderRadius="15px"
                          // borderColor="#659B1B"

                          color="white"
                          background="#0D75BA"
                          variant="contained"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              textTransform: "capitalize",
                              fontFamily: "Inter",
                              fontWeight: "500",
                            }}
                          >
                            Add
                          </Typography>
                        </CustomButton>
                      </div>
                    </DialogActions>
                  </CustomDialog>
                )}
              </>
              
            </div>
            
 
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              {console.log("celesta", mediaSrc)}
              {value?.messageText || upload === undefined ? (
  ""
) : (
  isImage ? (
    <img
      src={upload?.fileUrl}
      style={{ width: "50%", height: "50%", borderRadius: "15px" }}
      alt="Media"
    />
  ) : isVideo ? (
    <video style={{ width: "50%", height: "40%", borderRadius: "15px" }} controls>
      <source src={upload?.fileUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  ) : (
    <div>
                      <iframe src={upload?.fileUrl} width="100%" height="500px" />
                      </div>
  )
)}


            </div>

            {add ?(
              <>
                <div style={{ marginTop: "10px", width: "100%" }}>
                  {/* <Divider sx={{color:"black"}}/> */}
                  <hr />
                </div> 
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: smMatch
                      ? "30px 10px 30px 10px"
                      : "30px 30px 30px 30px",
                  }}
                >
                  <div style={{ width: "200px" }}>
                    <Typography
                      sx={{
                        fontWeight: isBold ? "700" : "normal",

                        fontStyle: isItalic ? "italic" : "normal",
                        //textAlign:"justify"
                      }}
                    >
                      {/* {previousSave
                        ? savedValues[3]?.content 
                        : previousNext
                        ? previousNext[0]?.body
                        : change} */}
                     {change}
                    </Typography>
                  </div> 
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEdit}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <hr />
                </div>
              </>
            ): returnFlag && savedContent ? (
              <>
              <div style={{ marginTop: "10px", width: "100%" }}>
                {/* <Divider sx={{color:"black"}}/> */}
                <hr />
              </div> 
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: smMatch
                    ? "30px 10px 30px 10px"
                    : "30px 30px 30px 30px",
                }}
              >
                <div style={{ width: "200px" }}>
                  <Typography
                    sx={{
                      fontWeight: isBold ? "700" : "normal",

                      fontStyle: isItalic ? "italic" : "normal",
                      //textAlign:"justify"
                    }}
                  >
                    {/* {previousSave
                      ? savedValues[3]?.content 
                      : previousNext
                      ? previousNext[0]?.body
                      : change} */}
                      {/* {console.log("huhahhaah",savedContent[3]?.content)} */}
                   {savedContent ? savedContent[3]?.content : null}
                  </Typography>
                </div> 
                <div style={{ display: "flex", gap: "10px" }}>
                  <div
                    style={{
                      marginTop: "0px",
                      alignItems: "center",
                      marginLeft: "0px",
                    }}
                  >
                    <img
                      src={Edit}
                      onClick={handleEdit}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "0px",
                      alignItems: "center",
                      marginLeft: "0px",
                    }}
                  >
                    <img
                      src={Delete}
                      style={{
                        cursor: "pointer",
                        marginLeft: smMatch ? "10px" : "40px",
                      }}
                      onClick={handleDelete}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <hr />
              </div>
            </>
            ): returnNextFlag && savedContent ?(
              <>
              <div style={{ marginTop: "10px", width: "100%" }}>
                {/* <Divider sx={{color:"black"}}/> */}
                <hr />
              </div> 
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: smMatch
                    ? "30px 10px 30px 10px"
                    : "30px 30px 30px 30px",
                }}
              >
                <div style={{ width: "200px" }}>
                  <Typography
                    sx={{
                      fontWeight: isBold ? "700" : "normal",

                      fontStyle: isItalic ? "italic" : "normal",
                      //textAlign:"justify"
                    }}
                  >
                    {/* {previousSave
                      ? savedValues[3]?.content 
                      : previousNext
                      ? previousNext[0]?.body
                      : change} */}
                      {/* {console.log("huhahhaah",savedContent[3]?.content)} */}
                   {savedContent ? savedContent[3]?.content : null}
                  </Typography>
                </div> 
                <div style={{ display: "flex", gap: "10px" }}>
                  <div
                    style={{
                      marginTop: "0px",
                      alignItems: "center",
                      marginLeft: "0px",
                    }}
                  >
                    <img
                      src={Edit}
                      onClick={handleEdit}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "0px",
                      alignItems: "center",
                      marginLeft: "0px",
                    }}
                  >
                    <img
                      src={Delete}
                      style={{
                        cursor: "pointer",
                        marginLeft: smMatch ? "10px" : "40px",
                      }}
                      onClick={handleDelete}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <hr />
              </div>
            </>
            ):(
              null
              )}
            {apply ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: smMatch
                      ? "30px 10px 30px 10px"
                      : "30px 30px 30px 10px",
                  }}
                >
                  <div style={{ textAlign: "left", width: "200px" }}>
                    {/* <CustomButton
                      width="170px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        style={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                        }}
                      >
                        {selectedLabel}
                      </Typography>
                    </CustomButton> */}
                    {tempSelection === "pair1" && (
                     
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                             {/* {selectedPair.split(' / ')[0]} */}
                             Interested
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedPair.split(' / ')[1]} */}
                            Not Interested
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {formik.values.radioSelection === "pair2" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            View Website
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Call Now
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {formik.values.radioSelection === "pair3" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Get Started
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Learn More
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {formik.values.radioSelection  === "pair4" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Subscribe
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            No Thanks
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {formik.values.radioSelection === "pair5" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Shop Now
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            View Details
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {formik.values.radioSelection === "pair6" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Claim Offer
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Skip Offer
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {formik.values.radioSelection === "pair7" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Schedule a Call
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Send a Message
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {formik.values.radioSelection=== "pair8" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Download App
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Use Web Version{" "}
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {formik.values.radioSelection=== "pair9" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Explore Products{" "}
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Request Info{" "}
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                   { formik.values.radioSelection === "pair10" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Apply Now{" "}
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Request More Info
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEditButton}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete1}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px", color: "black" }}>
                  <hr />
                </div>
              </>
            ):(
              returnNextFlag && savedContent ?(
                <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: smMatch
                      ? "30px 10px 30px 10px"
                      : "30px 30px 30px 10px",
                  }}
                >
                  <div style={{ textAlign: "left", width: "200px" }}>
                    {/* <CustomButton
                      width="170px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        style={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                        }}
                      >
                        {selectedLabel}
                      </Typography>
                    </CustomButton> */}
                    {tempSelection === "pair1" && (
                     
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {console.log("but",savedContent[4].value)}
                             {/* {selectedPair.split(' / ')[0]} */}
                             Interested
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {/* {selectedPair.split(' / ')[1]} */}
                            Not Interested
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {tempSelection === "pair2" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            View Website
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Call Now
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {tempSelection === "pair3" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Get Started
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Learn More
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {tempSelection === "pair4" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Subscribe
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            No Thanks
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {tempSelection === "pair5" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Shop Now
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            View Details
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {tempSelection === "pair6" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Claim Offer
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Skip Offer
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {tempSelection === "pair7" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Schedule a Call
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Send a Message
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {tempSelection === "pair8" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Download App
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Use Web Version{" "}
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                    {tempSelection === "pair9" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Explore Products{" "}
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Request Info{" "}
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                   { tempSelection === "pair10" && (
                      <div style={{ display: "flex", gap: "15px" }}>
                        <CustomButton
                          width="250px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Apply Now{" "}
                          </Typography>
                        </CustomButton>
                        <CustomButton
                          width="400px"
                          height="43px"
                          borderRadius="15px"
                          color="#0D75BA"
                          background="white"
                          variant="outlined"
                          border="5px solid #0D75BA"
                          borderColor="#0D75BA"
                        >
                          <Typography
                            sx={{
                              color: "#0D75BA",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Request More Info
                          </Typography>
                        </CustomButton>
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEditButton}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete1}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px", color: "black" }}>
                  <hr />
                </div>
              </>
              ):
        
           returnFlag ?
           (
            <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: smMatch
                  ? "30px 10px 30px 10px"
                  : "30px 30px 30px 10px",
              }}
            >
              <div style={{ textAlign: "left", width: "200px" }}>
                {/* <CustomButton
                  width="170px"
                  height="43px"
                  borderRadius="15px"
                  color="#0D75BA"
                  background="white"
                  variant="outlined"
                  border="5px solid #0D75BA"
                  borderColor="#0D75BA"
                >
                  <Typography
                    style={{
                      color: "#0D75BA",
                      textTransform: "capitalize",
                    }}
                  >
                    {selectedLabel}
                  </Typography>
                </CustomButton> */}
                {tempSelection === "pair1" && (
                 
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {console.log("but",savedContent[4].value)}
                         {/* {selectedPair.split(' / ')[0]} */}
                         Interested
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {/* {selectedPair.split(' / ')[1]} */}
                        Not Interested
                      </Typography>
                    </CustomButton>
                  </div>
                )}
                {tempSelection === "pair2" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        View Website
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Call Now
                      </Typography>
                    </CustomButton>
                  </div>
                )}
                {tempSelection === "pair3" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Get Started
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Learn More
                      </Typography>
                    </CustomButton>
                  </div>
                )}
                {tempSelection === "pair4" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {console.log("buttonns",savedContent[4].value)}
                        Subscribe
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        No Thanks
                      </Typography>
                    </CustomButton>
                  </div>
                )}
                {tempSelection === "pair5" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Shop Now
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        View Details
                      </Typography>
                    </CustomButton>
                  </div>
                )}
                {tempSelection === "pair6" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Claim Offer
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Skip Offer
                      </Typography>
                    </CustomButton>
                  </div>
                )}
                {tempSelection === "pair7" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Schedule a Call
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Send a Message
                      </Typography>
                    </CustomButton>
                  </div>
                )}
                {tempSelection === "pair8" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Download App
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Use Web Version{" "}
                      </Typography>
                    </CustomButton>
                  </div>
                )}
                {tempSelection === "pair9" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Explore Products{" "}
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Request Info{" "}
                      </Typography>
                    </CustomButton>
                  </div>
                )}
               {tempSelection === "pair10" && (
                  <div style={{ display: "flex", gap: "15px" }}>
                    <CustomButton
                      width="250px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Apply Now{" "}
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      width="400px"
                      height="43px"
                      borderRadius="15px"
                      color="#0D75BA"
                      background="white"
                      variant="outlined"
                      border="5px solid #0D75BA"
                      borderColor="#0D75BA"
                    >
                      <Typography
                        sx={{
                          color: "#0D75BA",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Request More Info
                      </Typography>
                    </CustomButton>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div
                  style={{
                    marginTop: "0px",
                    alignItems: "center",
                    marginLeft: "0px",
                  }}
                >
                  <img
                    src={Edit}
                    onClick={handleEditButton}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "0px",
                    alignItems: "center",
                    marginLeft: "0px",
                  }}
                >
                  <img
                    src={Delete}
                    style={{
                      cursor: "pointer",
                      marginLeft: smMatch ? "10px" : "40px",
                    }}
                    onClick={handleDelete1}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: "20px", color: "black" }}>
              <hr />
            </div>
          </>
           )
           : 
           null
           )}
            
            {one ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "20px 20px 20px 20px",
                  }}
                >
                  <div style={{ textAlign: "left", width: "200px" }}>
                    <Typography
                      sx={{ fontFamily: "Roboto" }}
                      fontSize={contentFontsize_14}
                    >
                      {footerText}
                    </Typography>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEditFooter}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete2}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "20px", color: "black" }}>
                  <hr />
                </div>
              </>
            ):(
              returnFlag && savedContent ? (
                <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "20px 20px 20px 20px",
                  }}
                >
                  <div style={{ textAlign: "left", width: "200px" }}>
                    <Typography
                      sx={{ fontFamily: "Roboto" }}
                      fontSize={contentFontsize_14}
                    >
                      {footerText}
                    </Typography>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEditFooter}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete2}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "20px", color: "black" }}>
                  <hr />
                </div>
              </>
              ):(
                returnNextFlag && savedContent ?(
                  <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "20px 20px 20px 20px",
                  }}
                >
                  <div style={{ textAlign: "left", width: "200px" }}>
                    <Typography
                      sx={{ fontFamily: "Roboto" }}
                      fontSize={contentFontsize_14}
                    >
                      {footerText}
                    </Typography>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Edit}
                        onClick={handleEditFooter}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        alignItems: "center",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={Delete}
                        style={{
                          cursor: "pointer",
                          marginLeft: smMatch ? "10px" : "40px",
                        }}
                        onClick={handleDelete2}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "20px", color: "black" }}>
                  <hr />
                </div>
              </>
                ) : null
              )
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "40px",
                padding: "0px 10px 0px 0px",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <div>
                  <CustomButton
                    background="#0D75BA"
                    borderRadius="10px"
                    width="90px"
                    onClick={handlePrevious}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Previous
                    </Typography>
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    background="#0D75BA"
                    borderRadius="10px"
                    width="90px"
                    //onClick={handleNext}
                     type="submit"
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Next
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
                padding: "0px 10px 0px 0px",
              }}
            >
              <Typography>Step 2 of 3</Typography>
            </div>
            </form>

          </div>
        </div>
      </div>
    </>
  );
}
