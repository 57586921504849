/* eslint-disable no-unused-vars */
import { ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";


// Create an upload link for file uploads:
const uploadLink = createUploadLink({
  // uri: 'https://demoserver.nearshopz.com/marketingportal',
  uri:`${process.env.REACT_APP_CLIENT_URL}` // Your GraphQL endpoint
});

// Create an auth link for adding the authorization header:
const authLink = setContext((_, { headers }) => {
  // Retrieve the token from local storage or any other source
  const token = localStorage.getItem('authToken');

  // Return the headers with the authorization token:
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});

// Combine the auth link and the upload link
const link = ApolloLink.from([authLink, uploadLink]);

// Create the Apollo Client:
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
