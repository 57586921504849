import { Dialog, DialogContent, Box, MenuItem, Typography, Select, FormControl, useMediaQuery, useTheme, TextField, InputAdornment, Drawer, Avatar, Popover, ThemeProvider, createTheme, AvatarGroup } from '../MUIcomponents/index';
import { contentFontSize_20, contentFontSize_16, HeaderFontSize_26, contentFontsize_14_xs_10, HeaderNavigationFontSize_12, contentFontsize_14 } from '../Typography'
import React, { useEffect, useRef, useState } from 'react'
import './Stores.css'
import closeIcon from '../../Utils/Images/closeIcon.png'
import avatar from '../../Utils/Images/avatar.png';
import call from '../../Utils/Images/call.png'
import mobile from '../../Utils/Images/mobile.png'
import email from '../../Utils/Images/email.png'
import whatsappImg from '../../Utils/Images/whatsappImg.png'
import send from '../../Utils/Images/send.png'
import readreceipt from '../../Utils/Images/readreceipt.png'
import { Divider, Radio } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CropperModal from './CropperModal';
import ImageIcon from '@mui/icons-material/Image';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CropIcon from '@mui/icons-material/Crop';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import man from '../../Utils/Images/man.jpg'
import singleTick from '../../Utils/Images/singleTick.png'
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { getMessageList, getSendMessage, getSendMessageToAll, getSendTemplate, GetStoreDetails, getTemplateList } from '../../graphql/actions'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CustomCard from '../Commoncomponents/card';
import { io } from 'socket.io-client';
import { Button, Modal } from "@mui/material";
import MessageTemplateModal from './MessageTemplateModal';
import TemplateMessage from './TemplateMessage';
import './Stores.css';
import axios from 'axios';
import { getStatusIcon } from './statusIcon';
import moment from 'moment';

export default function GroupChat({ open12, handleClose12, setOpen12, length, open13, setOpen13 }) {
  const [open1, setOpen1] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [file, setFile] = React.useState(null);
  const [filePreview, setFilePreview] = React.useState(null)
  const [cropImage, setCropImage] = React.useState(false)
  const [open4, setOpen4] = React.useState(false)
  const [individual, setIndividual] = React.useState(false)
  const [acknowledgement, setAcknowledgement] = React.useState(false)
  const [showError, setShowError] = useState(false);
  const [storeeId, setId] = useState(null);
  const [messageContent, setMessageContent] = useState("");
  // const socket = io('https://demoserver.nearshopz.com');
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`)



  const dispatch = useDispatch();

  useEffect(() => {
    // Listen for new messages from the server
    socket.on('newMessage', (data) => {
      console.log("daataa",data?.messages)
  const Message=  data?.messages?.map((message, index) => ({
    text: message.text,
    sender: message.sentBy,
    dateTime:message?.dateTime,
    sent: true,
    image: avatar,
    id: message?.wamid,
    businessType: "Restaurants",
    file,
    filePreview,
    status:message?.status,
    messageType: message?.messageType,
    templateId:message?.templateId
  })) || [];
      setMessages(Message);
  });
  
  
    // Clean up the effect
    return () => {
      socket.off('newMessage');
    };
  }, []);


  const chatData = useSelector((state) => state?.marketingSlice?.chatData);

  useEffect(() => {
    // Check if chatData is available and messages exist
    if (chatData?.getMessages[0]) {
      const storeMessages = chatData?.getMessages[0]?.messages?.map((message, index) => ({
        text: message.text,
        sender: message.sentBy,
        sent: true,
        image: avatar,
        id: message?.wamid,
        businessType: "Restaurants",
        file,
        filePreview,
        status:message?.status,
        messageType: message?.messageType,
        templateId:message?.templateId,
        dateTime:message?. dateTime
      })) || [];

      setMessages(storeMessages);
    }
  }, [chatData]);


  const { checkBoxDetails, storeData, rowDetails,data } = useSelector((state) => state.marketingSlice);
  useEffect(() => {
    if (checkBoxDetails && checkBoxDetails.payload || rowDetails.payload) {
      const trueCounts = rowDetails.payload ? Object.values(rowDetails.payload)?.filter(value => value === true)?.length : null;
      const trueCount = checkBoxDetails.payload ? Object?.values(checkBoxDetails?.payload)?.filter(value => value === true)?.length : null
      const trueKeys = Object.entries(
        checkBoxDetails?.payload ? checkBoxDetails.payload : rowDetails.payload
      )
        .filter(([key, value]) => value === true)
        .map(([key]) => key);

      if (trueCount === 1 || trueCounts === 1) {
        setIndividual(true);
        dispatch(GetStoreDetails([trueKeys[0]])); // Assuming this fetches the store details
        setShowError(false);

      }

      else if (trueCount === 0) {
        setShowError(true)
        setTimeout(() => {
          setOpen12(false)
        }, 500)

      }
      else {
        setIndividual(false);
        setShowError(false)
      }
    } else {
      setShowError(true)
      setTimeout(() => {
        setOpen12(false)
      }, 500)
    }


  }, [checkBoxDetails, rowDetails]); // Ensure rowDetails is included if it changes

  useEffect(() => {
    if (storeData?.getStoreDetails?.length === 1) {
      dispatch(getMessageList(storeData?.getStoreDetails[0]?.id));
    }
    else if(storeData?.getStoreDetails[0]?.length === 1)
    {
      dispatch(getMessageList(storeData?.getStoreDetails[0]?.id));
    }
    else {
      setMessages([])
    }
  }, [storeData])

  
  useEffect(() => {
    if (showError && length) {
      message.error({
        content: "please choose any stores",
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
    }
  }, [showError])


  const handleClickClose4 = () => {
    setOpen4(false);
  };

  const handleCropImage = () => {
    setCropImage(true);
    setOpen4(true);
  };

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const open2 = Boolean(anchorEl);


  useEffect(() => {
    if (filePreview) {
      setAnchorEl(null)
      setAnchorEl1(true)
    }

  }, [filePreview])

  const handleClose3 = () => {
    setAnchorEl1(null);
    setFile(null)
    setFilePreview(null);
  };
  const open3 = Boolean(anchorEl1);
  const toggleDrawer = (newOpen) => () => {
    setOpen1(newOpen);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"))
  const upsmmatch = useMediaQuery(theme.breakpoints.up("sm"));
  const upLgmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const [select, setSelect] = useState('')

  const [messages, setMessages] = useState([]);
  const [replyContext, setReplyContext] = useState(null);
  const [newMessage, setNewMessage] = useState('')
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);
  const handleEmojiClick = (emojiObject) => {
    setInput((prevMessage) => prevMessage + emojiObject.emoji);

  };

 

  const findPhoneNumberById = (stores, id) => {
    const store = stores?.find(store => store.id === id);

    if (store) {
      // Return the first available number, prioritizing mobileNo, then whatsappNo, then altMobileNo
      return store.mobileNo || store.whatsappNo || store.altMobileNo || "No phone number available";
    } else {
      return "Store not found";
    }
  };




  const handleSend = () => {
    const message = input;

    // dispatch(getSendTemplate(phoneNumber,selectedTemplates[0]?.templateName))
    // dispatch(getSendMessageToAll())
    if (input.trim() !== '') {
      // Add the message to the local message list
      setMessages((prevMessages = []) => [
        ...prevMessages,
        {
          text: input,
          sent: true,
          sender: "Nearshopz",
          image: avatar,
          id: messages.length + 1,
          businessType: "Restaurants",
          file,
          filePreview,
        },
      ]);

      // Clear the input and other states
      setInput('');
      setNewMessage('');
      setReplyContext(null);
      setFile(null);
      setFilePreview(null);
      setShowEmojiPicker(false);

      const storeId = Object.keys(checkBoxDetails?.payload).find(key => checkBoxDetails?.payload[key] === true);
      const phoneNumber = findPhoneNumberById(storeData?.getStoreDetails, storeId);
      dispatch(getSendMessage(phoneNumber, message, Object.keys(checkBoxDetails?.payload).find(key => checkBoxDetails?.payload[key] === true)));
    }
  };

  useEffect(() => {
    if (filePreview) {
      setAnchorEl(null);
      setAnchorEl1(true);
    }
  }, [filePreview]);


  const handletemplateclose = () => {
    setAnchorEl(null);
    setOpen1(false)
    toggleDrawer(false)
  }
  const StatusFilter = [
    { id: 1, label: "Accepted" },
    { id: 2, label: "rejected" },
    { id: 3, label: "completed" }
  ]
  const listData = useSelector((state) => state.marketingSlice?.listData);

  useEffect(() => {
    dispatch(getTemplateList())
  }, [])


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFilePreview(URL.createObjectURL(selectedFile));
  };

  const hnadleReply = (message) => {
    setReplyContext(message);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  const handleChange = (event) => {
    setSelect(event.target.value);
  };


  const storeNames = [
    storeData?.getStoreDetails.map((item) => {
      return item.storeName;
    }),
  ];

  const [templateDetails, setTemplateDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [templateView, setTemplateView] = useState([]);
  const fetchTemplateDetails = async (templateName,whatsAppTemplateId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_VIEW_URL}`, {
       
        whatsAppTemplateId
    
      });
      setTemplateView(response?.data);
      const [buttonText1, buttonText2] = response?.data?.button.split(" / ");
      return {
        templateName: `${templateName}`,
        templateBody: `${response?.data?.body}`,
        previewImageUrl: "https://www.webwise.ie/wp-content/uploads/2015/01/WhatsApp-header.jpg",
        timestamp: '9.30pm',
        button1Text: buttonText1,
        button2Text: buttonText2,
        footerText: response?.data?.footer
      };
    } catch (error) {
      console.error('Error ', error);
    }

  };

  const handleRadioChange = async (id, templateName,whatsAppTemplateId) => {
    const templateId = id; // Assuming the template ID is used for selection
    const details = await fetchTemplateDetails(templateName,whatsAppTemplateId);
    setTemplateDetails(details);
    setShowPopup(true);
    setSelectedTemplates((prevState) => {
      const newSelection = { id, templateName };
      return [...prevState.filter((item) => item.id !== id), newSelection];
    });
  };

  
  const { searchStoreData} = useSelector((state) => state.marketingSlice);
  const handleConfirmTemplate = () => {
    const filteredTemplate = listData?.listTemplates?.filter(
      (template) => template.templateName === templateView?.name
    );
   
    // const storeId = Object.keys(checkBoxDetails?.payload).find(key => checkBoxDetails?.payload[key] === true);
    const storeIds = Object.keys(checkBoxDetails?.payload).filter(
      (key) => checkBoxDetails?.payload[key] === true
    );
    const phoneNumbers = storeIds.map((id) =>
      findPhoneNumberById(data?.listStores, id)
    );
    if (templateView) {
      setMessageContent(templateView?.components && templateView?.components[1]?.text);
      setMessages((prevMessages = []) => [
        ...prevMessages,
        {
          text: templateView?.components && templateView?.components[1]?.text,
          sent: true,
          sender: "Nearshopz",
          image: avatar,
          id: messages?.length + 1,
          businessType: "Restaurants",
          file,
          filePreview,
          messageType: 'template'
        },
      ]);
      setShowPopup(false);
      const recipients = storeIds.map((id, index) => ({
        storeId: id,
        phoneNumber: `91${phoneNumbers[index]}`,
      }));
      let input= {
        recipients:recipients,
        templateName:templateView?.name,
        templateId:filteredTemplate[0]?.id,
        mediaId:filteredTemplate[0]?.mediaId
      }
      dispatch(getSendTemplate(input))
    }
  };
  const users = [
    {
      id: 1,
      img: man,
      name: "Nearshopz",
      description: "this is hello world message",
      time: "2:00 pm",
    },
    {
      id: 2,
      img: man,
      name: "Ashiya",
      description: "this is hello world message",
      time: "2:00 pm",
    },
    {
      id: 3,
      img: man,
      name: "Celesta",
      description: "this is hello world message",
      time: "2:00 pm",
    },
    {
      id: 4,
      img: man,
      name: "Amurtha",
      description: "this is hello world message",
      time: "2:00 pm",
    },
    {
      id: 5,
      img: man,
      name: "Archana",
      description: "this is hello world message",
      time: "2:00 pm",
    },
  ];

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    const element = document.getElementById('messages');
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };
  const now = new Date();

  // Get hours, minutes, and AM/PM
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour format to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format time
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  const formatTimestamp = (dateTime) => {
    const isNumeric = /^\d+$/.test(dateTime); // Check if the string contains only digits
    return moment(
      isNumeric ? Number(dateTime) : dateTime // Convert numeric string to a number; use ISO string as-is
    ).format('h:mm A');
  };

  return (
    <div >
      {!showError && (
        <Dialog
          open={open12 || open13}
          onClose={handleClose12}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"1000"}
        >
          <DialogContent
            sx={{ padding: "0px", borderBottom: "1px solid #DADADA" }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                overflowX: smmatch && "hidden",
              }}
            >
              <div style={{ padding: "20px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {!individual ? (

                    <div style={{ marginLeft: "20px" }}>
                      <ThemeProvider
                        theme={createTheme({
                          components: {
                            MuiAvatarGroup: {
                              styleOverrides: {
                                root: {
                                  display: "flex",
                                  "& .MuiAvatar-root": {
                                    marginLeft: "-20px", // Overlap avatars
                                    width: 46,
                                    height: 46,
                                    zIndex: "auto", // Default z-index for avatars
                                    transition: "transform 0.3s ease", // Smooth transition for hover
                                  },
                                  "& .MuiAvatar-root:first-of-type": {
                                    zIndex: 2, // Fix first avatar in place
                                    transform: "none", // No hover effect for the first avatar
                                  },
                                  // Move all avatars except the first to the right when hovered
                                  "& .MuiAvatar-root:not:hover": {
                                    transform: "translateX(20px)", // Move to the right on hover
                                  },
                                },
                              },
                            },
                          },
                        })}
                      >
                        <AvatarGroup max={2}>
                          {" "}
                          {/* Adjust max to display all avatars */}
                          {storeNames[0]?.map((storeName, index) => (
                            <Avatar key={index} sx={{ bgcolor: "#0D75BA" }}>
                              {storeName.toString()?.charAt(0).toUpperCase()}{" "}
                              {/* First letter of store name */}
                            </Avatar>
                          ))}
                        </AvatarGroup>
                      </ThemeProvider>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            background: "#13801C",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <Typography
                          className="chatFont"
                          fontSize={contentFontSize_16}
                        >
                          Accepted
                        </Typography>
                      </div>
                    </>
                  )}
                  <div style={{ cursor: "pointer" }} onClick={handleClose12}>
                    <img
                      src={closeIcon}
                      alt="closeicon"
                      width={matches ? "13px" : "20px"}
                      height={matches ? "13px" : "20px"}
                    />{" "}
                  </div>
                </div>

                {individual && (
                  <>
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: matches && "wrap",
                      }}
                    >
                      <div style={{ display: "flex", gap: "20px" }}>
                        <div>
                          <AvatarGroup max={2}>
                            {" "}
                            {/* Adjust max to display all avatars */}
                            {storeNames[0]?.map((storeName, index) => (
                              <Avatar key={index} sx={{ bgcolor: "#0D75BA" }}>
                                {storeName.toString()?.charAt(0).toUpperCase()}{" "}
                                {/* First letter of store name */}
                              </Avatar>
                            ))}
                          </AvatarGroup>
                        </div>
                        <div style={{ width: "400px" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: matches ? "1px" : "5px",
                            }}
                          >
                            <Typography
                              className="chatFontHeebo"
                              fontSize={HeaderFontSize_26}
                            >
                              {storeData?.getStoreDetails[0]?.storeName}
                            </Typography>
                            <Typography
                              className="chatFontHeebo400"
                              fontSize={contentFontSize_20}
                            >
                              {storeData?.getStoreDetails[0]?.businessType?.join(
                                ", "
                              )}
                            </Typography>
                            <Typography
                              className="chatFontHeebo400Color"
                              fontSize={contentFontsize_14_xs_10}
                            >
                              {storeData?.getStoreDetails[0]?.district}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: smmatch ? "10px" : "20px",
                            flexWrap: smmatch && "wrap",
                            marginTop: matches && "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: smmatch && "wrap",
                            }}
                          >
                            <div className="centerAlign">
                              <img src={call} alt="call image" />{" "}
                            </div>
                            <Typography
                              className="chatFontHeebo400"
                              fontSize={contentFontSize_16}
                            >
                              {storeData?.getStoreDetails[0]?.mobileNo}
                            </Typography>
                          </div>
                          {storeData?.getStoreDetails[0]?.altMobileNo && (
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                flexWrap: smmatch && "wrap",
                              }}
                            >

                              <div className="centerAlign">
                                {" "}
                                <img src={mobile} alt="mobile image" />{" "}
                              </div>
                              <Typography
                                className="chatFontHeebo400"
                                fontSize={contentFontSize_16}
                              >
                                {storeData?.getStoreDetails[0]?.altMobileNo}
                              </Typography>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: smmatch ? "10px" : "20px",
                            flexWrap: smmatch && "wrap",
                          }}
                        >
                          {storeData?.getStoreDetails[0]?.whatsappNo && (
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                flexWrap: smmatch && "wrap",
                              }}
                            >
                              <div className="centerAlign">
                                {" "}
                                <img
                                  src={whatsappImg}
                                  alt="whatsapp image"
                                />{" "}
                              </div>
                              <Typography
                                className="chatFontHeebo400"
                                fontSize={contentFontSize_16}
                              >
                                {storeData?.getStoreDetails[0]?.whatsappNo}
                              </Typography>
                            </div>
                          )}
                          {storeData?.getStoreDetails[0]?.emailId && (
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                flexWrap: smmatch && "wrap",
                              }}
                            >
                              <div className="centerAlign">
                                {" "}
                                <img src={email} alt="email image" />{" "}
                              </div>
                              <Typography
                                className="chatFontHeebo400"
                                fontSize={contentFontSize_16}
                              >
                                {storeData?.getStoreDetails[0]?.emailId}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="chatSelect">
                      <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
                        <FormControl fullWidth>
                          <Select
                            sx={{
                              borderRadius: "10px",
                              background: "#0D75BA",
                              color: "white",
                              height: matches ? "36px" : "42px",
                              width: "100%",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0D75BA",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#0D75BA",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0D75BA",
                              },
                            }}
                            displayEmpty
                            value={select}
                            onChange={handleChange}
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <Typography
                                    style={{
                                      fontFamily: "Heebo",
                                      fontWeight: "400",
                                      color: "white",
                                    }}
                                    fontSize={contentFontSize_16}
                                  >
                                    Select
                                  </Typography>
                                );
                              }
                              return (
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                  }}
                                  fontSize={contentFontSize_16}
                                >
                                  {selected}
                                </Typography>
                              );
                            }}
                          >
                            {StatusFilter.map((item) => (
                              <MenuItem key={item.id} value={item.label}>
                                <Typography
                                  style={{
                                    fontFamily: "Heebo",
                                    fontWeight: "400",
                                  }}
                                  fontSize={contentFontSize_16}
                                >
                                  {item.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  </>
                )}
              </div>
              {/* {smmatch && <div style={{ marginTop: "10px" }}> <CustomButton background="#0D75BA" onClick={toggleDrawer(true)}><Typography style={{ fontFamily: "Heebo", fontWeight: "400", textTransform: "capitalize" }} fontSize={contentFontSize_16}>Templates</Typography></CustomButton></div>} */}
              <Dialog open={open1} onClose={handletemplateclose}>
                <div style={{ margin: "0px 0px" }}>
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <Typography
                      className="chatFont"
                      fontSize={contentFontSize_20}
                    >
                      Templates
                    </Typography>
                  </div>
                  <div style={{ height: "500px", overflowY: "scroll" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: matches ? "230px" : "100px",
                        padding: "20px",
                      }}
                    >
                      {listData?.listTemplates?.map((item) => (
                      
                        <div
                          key={item.id}
                          style={{
                            display: "flex",
                            flexDirection: lgmatch ? "column" : "row",
                            justifyContent: matches
                              ? "center"
                              : "space-evenly",
                            marginTop: "20px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginLeft: "0px",
                            }}
                          >
                            <>
                              <CustomCard
                                width="200px"
                                height="100%"
                                padding="16px 16px"
                                border="1px solid #0D75BA"
                                borderRadius="10px"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontFamily: "Heebo",
                                      }}
                                      fontSize={contentFontSize_16}
                                    >
                                      {item?.templateName}
                                    </Typography>
                                  </div>
                                </div>
                              </CustomCard>
                            </>
                          </div>
                          <Radio
  checked={selectedTemplates.some(
    (template) => template.id === item.id
  )}
  onChange={() => handleRadioChange(item.id, item.templateName,item?.whatsAppTemplateId)} // Removed `e` and added a direct call
  value={item.id}
  name="template-radio-button"
  inputProps={{ "aria-label": item.templateName }}
/>

                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog>


              <div style={{ display: "flex", borderTop: "1px solid #DADADA" }}>
                {upsmmatch && (
                  <div style={{ margin: "0px 0px" }}>
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <Typography
                        className="chatFont"
                        fontSize={contentFontSize_20}
                      >
                        Templates
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: matches ? "230px" : "300px",
                        padding: "20px",
                        overflowY: "scroll",
                        height: "400px",
                        msOverflowStyle: "none",
                        scrollbarWidth: "none",
                      }}
                    >
                      {listData?.listTemplates?.map((item) => (
                        <div
                          key={item.id}
                          style={{
                            display: "flex",
                            flexDirection: lgmatch ? "column" : "row",
                            justifyContent: matches ? "center" : "space-evenly",
                            marginTop: "20px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginLeft: "0px",
                            }}
                          >
                            <>
                              <CustomCard
                                width="200px"
                                height="100%"
                                padding="16px 16px"
                                border="1px solid #0D75BA"
                                borderRadius="10px"
                                boxShadow="0px 0px 18px 7px #0000001A"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontFamily: "Heebo",
                                      }}
                                      fontSize={contentFontSize_16}
                                    >
                                      {item?.templateName}
                                    </Typography>
                                  </div>
                                </div>
                              </CustomCard>
                            </>
                          </div>
                          <Radio
  checked={selectedTemplates.some(
    (template) => template.id === item.id
  )}
  onChange={() => handleRadioChange(item.id, item.templateName,item?.whatsAppTemplateId)} // Removed `e` and added a direct call
  value={item.id}
  name="template-radio-button"
  inputProps={{ "aria-label": item.templateName }}
/>

                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <MessageTemplateModal
                  showPopup={showPopup}
                  setShowPopup={setShowPopup}
                  templateDetails={{
                    templateName: templateView?.name,
                    previewImageUrl: templateView?.components && templateView?.components[0]?.example?.header_handle[0],
                    templateBody: templateView?.components && templateView?.components[1]?.text,
                    footerText: templateView?.components && templateView?.components[2]?.text,
                    timestamp: new Date()?.toLocaleTimeString(),
                    button1Text: templateView?.components && templateView?.components[3]?.buttons[0]?.text,
                    button2Text: templateView?.components && templateView?.components[3]?.buttons[1]?.text,
                    status:templateView?.status,
                    disabled:templateView?.status==="APPROVED"?false:true
                  }}
                  handleConfirmTemplate={handleConfirmTemplate}
                />
                <div
                  className="chatBackground"
                  style={{
                    width: "800px",
                    height: "516px",
                    borderLeft: "1px solid #DADADA",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1, // Allow child div to fill the available height
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "68px",
                        borderBottom: "1px solid #E5E5EA",
                        padding: "0px 10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        {individual ? (
                          <Avatar
                            alt="Remy Sharp"
                            src={avatar}
                            sx={{ width: 24, height: 24 }}
                          />
                        ) : (
                          <div></div>
                        )}
                        <div style={{ alignItems: "center", width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "700",
                                textAlign: "center",
                              }}
                              fontSize={contentFontSize_20}
                            >
                              {individual ? (
                                <>{storeData?.getStoreDetails?.storeName}</>
                              ) : (
                                <>Messengers</>
                              )}
                            </Typography>
                            <Typography
                              className="chatFontHeebo400Color"
                              fontSize={HeaderNavigationFontSize_12}
                              style={{ textAlign: "center" }}
                            >
                              {individual ? (
                                <>last seen 45 minutes ago</>
                              ) : (
                                <>Active</>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="messages" style={{ overflowY: 'auto', height: '400px' }}>
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "400",
                          color: "#666668",
                          position: "sticky",
                          top: "0px",
                          zIndex: "10",
                          textAlign: "center", // Center the text within the container
                        }}
                        fontSize={HeaderNavigationFontSize_12}
                      >
                        8/20/2020
                      </Typography>
                      {!acknowledgement ? (
                        <div >
                          
                          {messages?.map((message, index) => (
                          //  console.log("message?.templateId?.",message)
                            message?.messageType === "template" ?
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width: "100%", // Full width to align to the right
                                }}
                              >
                                <div style={{
                                  width: "50%",
                                  paddingBottom: "10px"
                                }}>
                                  {console.log("MMMM",message,templateView)}
                                {message?.templateId?
                                 <TemplateMessage templateDetails={
                                  {
                                    templateName: "",
                                    previewImageUrl: message?.templateId?.header?.imageUrl                                    ,
                                    templateBody: message?.templateId?.body,
                                    footerText:message?.templateId?.footer,
                                    timestamp: message?.dateTime,
                                    button1Text:message?.templateId?.button?.split('/')[0]?.trim(),
                                    button2Text: message?.templateId?.button?.split('/')[1]?.trim(),
                                    status:message?.status
                                  }}
                                  onButtonClick1={() => console.log("Approve clicked")}
                                  onButtonClick2={() => console.log("Decline clicked")}
                                />
                              :
                              <TemplateMessage templateDetails={
                                {
                                  templateName: templateView?.name,
                                  previewImageUrl: templateView?.components && templateView?.components[0]?.example?.header_handle[0],
                                  templateBody: templateView?.components && templateView?.components[1]?.text,
                                  footerText: templateView?.components && templateView?.components[2]?.text,
                                  timestamp:  new Date()?.toLocaleTimeString(),
                                  button1Text: templateView?.components && templateView?.components[3]?.buttons[0]?.text,
                                  button2Text: templateView?.components && templateView?.components[3]?.buttons[1]?.text,
                                  status:templateView?.status
                                }}
                                onButtonClick1={() => console.log("Approve clicked")}
                                onButtonClick2={() => console.log("Decline clicked")}
                              />
                              }
                                 
                                </div>
                              </div>
                              :
                              <div
                                key={index}
                                className="message"
                                style={{
                                  display: "flex",
                                  justifyContent:
                                    message.sender === "Nearshopz"
                                      ? "flex-end"
                                      : "flex-start",
                                  width:
                                    message.sender === "Nearshopz" ? "100%" : "80%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    width: "100%",
                                    justifyContent:
                                      message.sender === "Nearshopz"
                                        ? "flex-end"
                                        : "flex-start",
                                  }}
                                >
                                  {message.sender !== "Nearshopz" && (
                                    <Avatar
                                      src={message.image}
                                      alt="avatar"
                                      sx={{ width: 24, height: 24 }}
                                    />
                                  )}
                                  <div
                                    style={{
                                      maxWidth: "50%",
                                      minWidth: "30%",
                                      height: "100%",
                                      background:
                                        message.sender === "Nearshopz"
                                          ? "#FFFF"
                                          : "#F2F2F7",
                                      color:
                                        message.sender === "Nearshopz"
                                          ? "black"
                                          : "black",
                                      borderRadius: "6px",
                                      padding: matches
                                        ? "10px 10px 15px 10px"
                                        : "10px 10px 25px 10px",
                                      position: "relative",
                                      wordWrap: "break-word",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        fontFamily: "Inter",
                                        fontWeight: "400",
                                      }}
                                      fontSize={contentFontsize_14}
                                    >
                                      {message.text}
                                    </Typography>
                                    {message.sender !== "Nearshopz" && (
                                      <Typography
                                        style={{
                                          fontFamily: "Inter",
                                          fontWeight: "400",
                                          color: "#666668",
                                          position: "absolute",
                                          top: "1px",
                                          left: smmatch ? "20%" : "30%",
                                        }}
                                        fontSize={HeaderNavigationFontSize_12}
                                      >
                                        {message.bussinessType}
                                      </Typography>
                                    )}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    position: "absolute",
                                    bottom: "10px",
                                    alignItems: "center",
                                    right: message.sender === "Nearshopz" && "15px",
                                    left:
                                      message.sender !== "Nearshopz" &&
                                      (smmatch ? "30%" : "20%"),
                                  }}
                                >
                                  <Typography
                                    className="chatFontHeebo400"
                                    style={{
                                      color:"#666668",
                                    }}
                                    fontSize={HeaderNavigationFontSize_12}
                                  >
                               {formatTimestamp(message?.dateTime)}

                                  </Typography>
                                  {message.sender === "Nearshopz" && (
                                <div style={{ float: "right", paddingTop: "6px" }}>{getStatusIcon(message?.status)}</div>
                                  )}
                                </div>
                              </div>
                          ))
                          }
                        </div>
                      ) : (
                        <>
                       
                        </>
                      )}
                      <div ref={messagesEndRef} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderTop: "1px solid #E5E5EA",
                        backgroundColor: "#fff",
                        position: "sticky",
                        top: 0, // Sticks to the top of the container or viewport
                        zIndex: 1000,
                      }}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="Start Typing...."
                        multiline
                        // rows='1'
                        value={input}
                        name="Search"
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyPress}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ width: "89px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100%",
                                  gap: "20px",
                                }}
                              >
                                {/* <div onClick={handleClick2} ><img src={Upload} alt='upload image' style={{ cursor: "pointer" }} /> </div> */}
                                <div onClick={handleSend}>
                                  {" "}
                                  <img
                                    src={send}
                                    alt="send icon"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "54px",
                                    }}
                                  />{" "}
                                </div>
                              </div>
                            </InputAdornment>
                          ),
                          sx: {
                            fontSize: matches && "13px",
                          },
                        }}
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            borderRadius: "16px",
                            "& fieldset": {
                              borderColor: "white", // This will change the border color
                              borderTop: "black",
                            },
                            "&:hover fieldset": {
                              borderColor: "white", // This will change the border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white", // This will change the border color when focused
                            },
                          },

                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {showEmojiPicker && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "60px",
                    right: "20px",
                    width: smmatch ? "210px" : "250px",
                    height: "300px",
                    overflow: "hidden",
                  }}
                >
                  <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    pickerStyle={{ width: "100%", height: "100%" }}
                  />
                </Box>
              )}
            </div>
          </DialogContent>
        </Dialog>
      )}

      <Popover
        open={open2}
        anchorEl={anchorEl}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: 800,
          horizontal: 400,
        }}
        transformOrigin={{
          vertical: 500,
          horizontal: 500,
        }}
        PaperProps={{
          style: {
            marginTop: "-30px", // Adjust this value as needed to move the popover up
          },
        }}
      >
        <div>
          <input
            accept="image/*"
            id="image-input"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <input
            accept="video/*"
            id="video-input"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <input
            accept="application/pdf/*"
            id="file-input"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <input
            accept="audio/*"
            id="audio-input"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <div>
              <label htmlFor="image-input">
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ImageIcon
                    sx={{
                      color: "#0D75BA",
                      width: matches ? "20px" : "25px",
                      height: matches ? "20px" : "25px",
                    }}
                  />

                  <Typography
                    className="chatFontHeebo400Color"
                    fontSize={contentFontSize_16}
                  >
                    Images
                  </Typography>
                </div>
              </label>{" "}
            </div>
            <div>
              {" "}
              <label htmlFor="video-input">
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <OndemandVideoIcon
                    sx={{
                      color: "#0D75BA",
                      width: matches ? "20px" : "25px",
                      height: matches ? "20px" : "25px",
                    }}
                  />
                  <Typography
                    className="chatFontHeebo400Color"
                    fontSize={contentFontSize_16}
                  >
                    Video
                  </Typography>
                </div>
              </label>
            </div>
            <div>
              {" "}
              <label htmlFor="audio-input">
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <AudiotrackIcon
                    sx={{
                      color: "#0D75BA",
                      width: matches ? "20px" : "25px",
                      height: matches ? "20px" : "25px",
                    }}
                  />
                  <Typography
                    className="chatFontHeebo400Color"
                    fontSize={contentFontSize_16}
                  >
                    Audio
                  </Typography>
                </div>
              </label>
            </div>
            <div>
              {" "}
              <label htmlFor="file-input">
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <DocumentScannerIcon
                    sx={{
                      color: "#0D75BA",
                      width: matches ? "20px" : "25px",
                      height: matches ? "20px" : "25px",
                    }}
                  />
                  <Typography
                    className="chatFontHeebo400Color"
                    fontSize={contentFontSize_16}
                  >
                    Documents
                  </Typography>
                </div>
              </label>
            </div>
            {smmatch && (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={toggleDrawer(true)}
              >
                <AutoAwesomeMotionIcon
                  sx={{
                    color: "#0D75BA",
                    width: matches ? "20px" : "25px",
                    height: matches ? "20px" : "25px",
                  }}
                />
                <Typography
                  className="chatFontHeebo400Color"
                  fontSize={contentFontSize_16}
                >
                  Templates
                </Typography>
              </div>
            )}
          </div>
        </div>
      </Popover>

      <Popover
        open={open3}
        anchorEl={anchorEl1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 1200,
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            marginTop: "-30px", // Adjust this value as needed to move the popover up
          },
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "10px 10px 0px 10px",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {file && file.type.startsWith("image/") && (
              <>
                <div
                  onClick={handleCropImage}
                  style={{
                    textAlign: "left",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <CropIcon
                    sx={{
                      color: "#0D75BA",
                      height: matches ? "20px" : "25px",
                      width: matches ? "20px" : "25px",
                    }}
                  />
                </div>
                <img
                  src={filePreview}
                  alt="image"
                  width={matches ? "90px" : "130px"}
                  height={matches ? "90px" : "130px"}
                />
              </>
            )}
            {file && file.type.startsWith("video/") && (
              <video
                controls
                style={{
                  width: matches ? "90px" : "130px",
                  height: matches ? "90px" : "130px",
                }}
              >
                <source src={filePreview} type={file.type} />
              </video>
            )}
            {file && file.type.startsWith("audio/") && (
              <div
                style={{
                  height: "150px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <audio controls style={{ width: matches ? "100px" : "130px" }}>
                  <source src={filePreview} type={file.type} />
                </audio>
              </div>
            )}
            {file && file.type.startsWith("application/") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InsertDriveFileIcon
                  style={{ fontSize: matches ? "50px" : "70px" }}
                />
                <Typography
                  style={{ marginTop: "10px", textAlign: "center" }}
                  fontSize={contentFontSize_16}
                >
                  {file.name}
                </Typography>
                <a
                  href={filePreview}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "10px",
                    color: "#0D75BA",
                    fontSize: "14px",
                  }}
                >
                  Open
                </a>
              </div>
            )}
            <div
              style={{ marginTop: "10px", textAlign: "right", width: "100%" }}
            >
              <SendIcon
                sx={{
                  color: "#0D75BA",
                  height: matches ? "20px" : "25px",
                  width: matches ? "20px" : "25px",
                }}
              />
            </div>
            <div style={{ margin: "5px" }}>
              <Typography
                onClick={handleClose3}
                style={{
                  color: "#0D75BA",
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "7px",
                }}
                fontSize={contentFontsize_14_xs_10}
              >
                <CloseIcon
                  sx={{
                    height: matches ? "20px" : "25px",
                    width: matches ? "20px" : "25px",
                    color: "black",
                  }}
                />
              </Typography>
            </div>
          </div>
        </div>
      </Popover>

      {cropImage && (
        <Dialog open={open4} onClose={handleClickClose4}>
          <DialogContent>
            <CropperModal
              setFile={setFile}
              setFilePreview={setFilePreview}
              file={file}
              filePreview={filePreview}
              setOpen4={setOpen4}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

