// Install socket.io-client if you haven't already
// npm install socket.io-client

import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const socket = io(`${process.env.REACT_APP_SOCKET_URL}`); // Change PORT to your server's port

const MessageComponent = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        // Listen for new messages from the server
        socket.on('newMessage', (data) => {
            console.log('New message received:', data);
            setMessages((prevMessages) => [...prevMessages, data]);
        });
        // Clean up the effect
        return () => {
            socket.off('newMessage');
        };
    }, []);

    return (
        <div>
            <h1>Chat Messages</h1>
            <ul>
                {messages.map((msg, index) => (
                    <li key={index}>{JSON.stringify(msg)}</li>
                ))}
            </ul>
        </div>
    );
};

export default MessageComponent;
