import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: null,
  storeData:null,
  storeLoading:false,
  storeError:null,
  loginData:null,
  loginDataLoading:false,
  loginError:null,
  loginSuccess:false,
  logOutData:null,
  logOutDataLoading:false,
  logOutError:null,
  addStorData:null,
  addStoreLoading:false,
  addStoreError:null,
  addStoreDataSuccess:false,
  storeDeleteData:null,
  storeDeleteLoading:false,
  storeDeleteError:null,
  deleteStoreDataSuccess:false,
  storeUpdateData:null,
  storeUpdateError:null,
  storeUpdateLoading:false,
  storeUpdateDataSuccess:false,
  searchStoreData:null,
  searchStoreLoading:false,
  searchStoreError:null,
  checkBoxDetails:{},
  rowDetails:{},
  filterStoreData:null,
  filterStoreError:null,
  filterStoreLoading:false,
  filterStoreListData:null,
  filterStoreListError:null,
  filterStoreListLoading:false,
  getFilterStoreData:null,
  getFilterStoreError:null,
  getFilterStoreLoading:false,
  filterSaveData:null,
  filterSaveError:null,
  filterSaveLoading:false,
  datasearch:null,
  dataerror:null,
  dataLoading:false,
  saveData:null,
  saveError:false,
  saveLoading:false,
  setLiveData:null,
  setLiveError:false,
  setLiveLoading:false,  filterStoreBoolean:false,
  tokenData:null,
  tokenLoading:false,
  tokenError:false,
  deleteData:null,
  deleteLoading:false,
  deleteError:false,
  editData:null,
  editLoading:false,
  editError:false,
  imageData:null,
  imageLoading:false,
  imageError:false,
  messageData:false,
  messageLoading:false,
  messageError:false,
  deleteTemplateSuccess:false,
  logoutSuccess:false,
  filterDeleteData:null,
  filterDeleteLoading:false,
  filterDeleteError:false,
  filterDeleteSuccess:false,
  sendTemplateData:null,
  sendTemplateLoading:false,
  sendTemplateError:false,
  sendToAllData:null,
  sendToAllLoading:false,
  sendToAllError:false,
  chatData:null,
  chatLoading:false,
 chatError:false,
  returnFlag:false,
  returnNextFlag:false,
  saveSuccess:false,
  chatData:null,
  chatLoading:false,
 chatError:false,
 editTemplateSuccess:false,
 AllMsgData:null,
 AllMsgLoading:false,
 AllMsgError:false
};

const marketingSlice = createSlice({
  name: 'marketingSlice',
  initialState,
  reducers: {
    updateMessageLists: (state, action) => {
      state.AllMsgData = {
        ...state.AllMsgData,
        getAllMessages: action.payload,
      };
    },
  
  setClearFilterAck:(state)=>{
    // state.filterStoreData=null
    state.filterStoreError=null
  },
  setFilterStoreBoolean:(state)=>{
    state.filterStoreBoolean=false
  },

    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setStoreData:(state,action)=>{
        state.storeData=action.payload;
    },
    setStoreLoading:(state,action)=>{
        state.storeLoading=action.payload
    },
    setStoreError:(state,action)=>{
        state.storeError=action.payload
    },
    setAddStoreData:(state,action)=>{
        state.addStorData=action.payload
        state.addStoreDataSuccess=true
        
    },
    setAddStoreLoading:(state,action)=>{
        state.addStoreLoading=action.payload
    },
    setAddStoreError:(state,action)=>{
        state.addStoreError=action.payload
    },
    setClearAddStore:(state)=>{
        state.addStoreDataSuccess=false
        state.deleteStoreDataSuccess=false
        state.storeUpdateDataSuccess=false
    },
    setDeleteStoreData:(state,action)=>{
      state.storeDeleteData=action.payload
      state.deleteStoreDataSuccess=true
    },
    setDeleteStoreLoading:(state,action)=>{
      state.storeDeleteLoading=action.payload
    },
    setDeleteStoreError:(state,action)=>{
      state.storeDeleteError=action.payload
    },
    setUpdateStoreData:(state,action)=>{
      state.storeUpdateData=action.payload
      state.storeUpdateDataSuccess=true
    },
    setUpdateStoreError:(state,action)=>{
      state.storeUpdateError=action.payload
    },
    setUpdateStoreLoading:(state,action)=>{
      state.storeUpdateLoading=action.payload
    },
    setSearchStoreData:(state,action)=>{
      state.searchStoreData=action.payload
    },
    setSearchStoreLoading:(state,action)=>{
      state.searchStoreLoading=action.payload
    },
    setSearchStoreError:(state,action)=>{
      state.searchStoreError=action.payload
    },
    setSearchStoreClear:(state)=>{
      state.searchStoreData=null
       
    },
    setCheckBoxDetails:(state,action)=>{
      state.checkBoxDetails=action
    },
    setRowDetails:(state,action)=>{
      state.rowDetails=action
    },
    setFilterStoreData:(state,action)=>{
      state.filterStoreData=action
      state.filterStoreBoolean=true
    },
    setFilterStoreError:(state,action)=>{
     
      state.filterStoreError=action
    },
    setFilterStoreLoading:(state,action)=>{
      state.filterStoreLoading=action
    },
    setClearSearchData:(state)=>{
      state.searchStoreData=null
      state.data=null
    },
    setClearFilterData:(state)=>{
      state.filterStoreData=null
      state.data=null
    },
    setFilterStoreListData:(state,action)=>{
      state.filterStoreListData=action
    },
    setFilterStoreListError:(state,action)=>{
      state.filterStoreListError=action
    },
    setFilterStoreListLoading:(state,action)=>{
      state.filterStoreListLoading=action
    },
    setGetFilterData:(state,action)=>{
      state.getFilterStoreData=action
    },
    setGetFilterError:(state,action)=>{
      state.getFilterStoreError=action
    },
    setGetFilterLoading:(state,action)=>{
      state.getFilterStoreLoading=action
    },
    setSaveFilterData:(state,action)=>{
      state.filterSaveData=action
    },
    setSaveFilterError:(state,action)=>{
      state.filterSaveError=action
    },
    setSaveFilterLoading:(state,action)=>{
      state.filterSaveLoading=action
    },
    setClearSaveFilterData:(state)=>{
      state.filterSaveData=null
    },
    setDataSearch:(state,action)=>{
      state.datasearch=action
    },
    setDataError:(state,action)=>{
      state.dataerror=action
    },
    setDataLoading:(state,action)=>{
      state.dataLoading=false
    },
    setLoginSuccess:(state)=>{
      state.loginSuccess=false;
  },
  setLoginData:(state,action)=>{
    state.loginData=action.payload;
    state.loginSuccess=true;
  },
  setLoginDataLoading:(state,action)=>{
    state.loginDataLoading=action.payload
},
setLoginError:(state,action)=>{
    state.loginError=action.payload
},

setLogOutData:(state,action)=>{
state.logOutData=action.payload;
state.logoutSuccess = true;
},
setLogOutDataLoading:(state,action)=>{
state.logOutDataLoading=action.payload
},
setLogOutError:(state,action)=>{
state.logOutError=action.payload
},
resetLogOutData: (state) => {
state.logOutData = null;
},
clearLoginError: (state) => {
  state.loginError = null;
},
setSaveSuccess:(state)=>{
  state.saveSuccess=false;
},
setSaveData:(state,action)=>{
  state.saveData=action.payload;
  state.saveSuccess=true;
  },
  setSaveLoading:(state,action)=>{
  state.saveLoading=action.payload
  },
  setSaveError:(state,action)=>{
  state.saveError=action.payload
  },
  
  setListData:(state,action)=>{
    state.listData=action.payload;
    
    },
    setListLoading:(state,action)=>{
    state.listLoading=action.payload
    },
    setListError:(state,action)=>{
    state.listError=action.payload
    },
    
    setTokenData:(state,action)=>{
      state.tokenData=action.payload;
      },
      setTokenLoading:(state,action)=>{
      state.tokenLoading=action.payload
      },
      setTokenError:(state,action)=>{
      state.tokenError=action.payload
      },
      setDeleteTemplateSuccess:(state)=>{
        // state.templateDataSuccess=false
         state.deleteTemplateSuccess=false
         //state.storeListTemplateUpdateSuccess=false
       },
       setLogoutSuccess:(state)=>{
        state.logoutSuccess= false
       },
       setFilterDeleteSuccess:(state)=>{
              state.filterDeleteSuccess= false
       },
      setDeleteData:(state,action)=>{
        state.deleteData=action.payload;
        state.deleteTemplateSuccess = true; 
        },
        setDeleteLoading:(state,action)=>{
        state.deleteLoading=action.payload
        },
        setDeleteError:(state,action)=>{
        state.deleteError=action.payload
        }, 
        setEditTemplateSuccess:(state)=>{
          // state.templateDataSuccess=false
           state.editTemplateSuccess=false
           //state.storeListTemplateUpdateSuccess=false
         },
        setEditData:(state,action)=>{
          state.editData=action.payload;
          state.editTemplateSuccess = true; 
          },
          setEditLoading:(state,action)=>{
          state.editLoading=action.payload
          },
          setEditError:(state,action)=>{
          state.editError=action.payload
          }, 
       
        setImageData:(state,action)=>{
          state.imageData=action.payload;
          },
          setImageLoading:(state,action)=>{
          state.imageLoading=action.payload
          },
          setImageError:(state,action)=>{
          state.imageError=action.payload
          },  
          setMessageData:(state,action)=>{
            state.messageData=action.payload;
            },
            setMessageLoading:(state,action)=>{
            state.messageLoading=action.payload
            },
            setMessageError:(state,action)=>{
            state.messageError=action.payload
            },          
            setFilterDeleteData:(state,action)=>{
              state.filterDeleteData=action.payload;
              state.filterDeleteSuccess=true;
              },
              setFilterDeleteLoading:(state,action)=>{
              state.filterDeleteLoading=action.payload
              },
              setFilterDeleteError:(state,action)=>{
              state.filterDeleteError=action.payload
              },
              setSendTemplateData:(state,action)=>{
                state.sendTemplateData=action.payload;
                //state.filterDeleteSuccess=true;
                },
                setSendTemplateLoading:(state,action)=>{
                state.sendTemplateLoading=action.payload
                },
                setSendTemplateError:(state,action)=>{
                state.sendTemplateError=action.payload
                },
                setSendToAllData:(state,action)=>{
                  state.sendToAllData=action.payload;
                  //state.filterDeleteSuccess=true;
                  },
                  setSendToAllLoading:(state,action)=>{
                  state.sendToAllLoading=action.payload
                  },
                  setSendToAllError:(state,action)=>{
                  state.sendToAllError=action.payload
                  },
                  setReturnFlag: (state, action) => {
                    state.returnFlag = action.payload; // Set returnFlag to payload value
                  },
                  // Action to reset returnFlag back to false
                  resetReturnFlag: (state) => {
                    state.returnFlag = false;
                  },
                  setReturnNextFlag: (state, action) => {
                    state.returnNextFlag = action.payload; // Set returnFlag to payload value
                  },
                  // Action to reset returnFlag back to false
                  resetReturnNextFlag: (state) => {
                    state.returnNextFlag = false;
                  },
                  setChatData: (state, action) => {
                    state.chatData = action.payload;
                  },
                  setChatLoading: (state, action) => {
                    state.chatLoading = action.payload;
                  },
                  setChatError: (state, action) => {
                    state.chatError = action.payload;
                  },
                  setAllMsgData: (state, action) => {
                    state.AllMsgData = action.payload;
                  },
                  setAllMsgLoading: (state, action) => {
                    state.AllMsgLoading = action.payload;
                  },
                  setAllMsgError: (state, action) => {
                    state.AllMsgError = action.payload;
                  },
                  

    },
    

    
  
});

export const { setData, setLoading, setError,setStoreData,setStoreError,setStoreLoading,setLoginData,setLoginDataLoading,setLoginError,clearLoginError,setLogOutDataLoading,setLogOutError,setLogOutData,resetLogOutData,setAddStoreData,setAddStoreError,setAddStoreLoading,setClearAddStore,setDeleteStoreData,setDeleteStoreLoading,setDeleteStoreError,deleteStoreDataSuccess,setUpdateStoreData,setUpdateStoreLoading,setUpdateStoreError,storeUpdateDataSuccess,setSearchStoreData,setSearchStoreError,setSearchStoreLoading,setSearchStoreClear,setCheckBoxDetails,setFilterStoreData,setFilterStoreError,setFilterStoreLoading,setClearSearchData,setClearFilterData,setFilterStoreListData,setFilterStoreListError,setFilterStoreListLoading,setGetFilterData,setGetFilterError,setGetFilterLoading,setSaveFilterData,setSaveFilterError,setSaveFilterLoading,setClearSaveFilterData,setDataSearch,setDataError,setDataLoading
  ,setSaveData,setSaveError,setSaveLoading,setListData,setListLoading,setListError,setImageData,setImageLoading,setImageError
,setClearFilterAck,setFilterStoreBoolean,setTokenError,setTokenLoading,setTokenData,setDeleteError,setDeleteLoading,setDeleteData,
setMessageData,setMessageLoading,setMessageError,setDeleteTemplateSuccess,setFilterDeleteData,setFilterDeleteLoading,setFilterDeleteError,setSendTemplateLoading,setSendTemplateData,setSendTemplateError,
setFilterDeleteSuccess,setSendToAllData,setSendToAllLoading,setSendToAllError,setRowDetails,setLogoutSuccess,setLoginSuccess,
setEditData,setEditLoading,setEditError,setReturnFlag,resetReturnFlag,setReturnNextFlag,resetReturnNextFlag,setSaveSuccess,
setChatData,setChatLoading,setChatError,setEditTemplateSuccess,
setAllMsgData,setAllMsgLoading,setAllMsgError,updateMessageLists
} = marketingSlice.actions;
export default marketingSlice.reducer;
